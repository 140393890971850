import axios from 'axios';
import { getFirestore, collection, getDocs } from "firebase/firestore";

export const getNotifications = async (empresaId) => {
  try {
    const db = getFirestore();
    const notificationsRef = collection(db, `Empresas/${empresaId}/notifications`);
    const querySnapshot = await getDocs(notificationsRef);
    const notifications = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return notifications;
  } catch (error) {
    console.error("Erro ao buscar notificações:", error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId, empresaId) => {
  try {
    await axios.post('https://genematch.app/api/notifications/markAsRead', {
      notificationId,
      empresaId,
    });
  } catch (error) {
    console.error("Erro ao marcar notificação como lida:", error);
    throw error;
  }
};
