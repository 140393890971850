import React from "react";
import { saveAs } from "file-saver";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  LinearProgress,
} from "@mui/material";
import {
  Face3,
  Face2,
  InsertEmoticon,
  Visibility,
  ErrorOutline,
} from "@mui/icons-material";

const formatPercentage = (value) => `${parseFloat(value).toFixed(2)}%`;

const loadTemplatePdf = async () => {
  try {
    const response = await fetch("/template.pdf");
    if (!response.ok) {
      throw new Error("Failed to fetch the PDF template");
    }
    const existingPdfBytes = await response.arrayBuffer();
    return existingPdfBytes;
  } catch (error) {
    console.error("Error loading template PDF:", error);
    throw error;
  }
};

const Relatoriorapido = ({ results }) => {
  // Adicionando logs para depuração
  console.log("Resultados recebidos:", results);

  const generatePDF = async () => {
    try {
      const existingPdfBytes = await loadTemplatePdf();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const { width, height } = firstPage.getSize();

      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const fontSize = 12;

      firstPage.drawText("Relatório de Pareamento Fenotípico", {
        x: 50,
        y: height - 50,
        size: 24,
        font: await pdfDoc.embedFont(StandardFonts.HelveticaBold),
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`Data: ${new Date().toLocaleDateString()}`, {
        x: 50,
        y: height - 80,
        size: fontSize,
        font,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText("Resultados da Similaridade", {
        x: 50,
        y: height - 110,
        size: 18,
        font: await pdfDoc.embedFont(StandardFonts.HelveticaBold),
        color: rgb(0, 0, 0),
      });

      const drawResult = (label, value, x, y) => {
        firstPage.drawText(label, {
          x,
          y,
          size: fontSize,
          font,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(formatPercentage(value), {
          x: x + 150,
          y,
          size: fontSize,
          font,
          color: rgb(0, 0, 0),
        });
      };

      let currentY = height - 140;
      const yOffset = 20;

      drawResult("Similaridade Facial Geral", results.similarity, 50, currentY);
      currentY -= yOffset;
      drawResult(
        "Similaridade da Boca",
        results.mouth_similarity,
        50,
        currentY
      );
      currentY -= yOffset;
      drawResult(
        "Similaridade do Nariz",
        results.nose_similarity,
        50,
        currentY
      );
      currentY -= yOffset;
      drawResult(
        "Similaridade dos Olhos",
        results.eye_similarity,
        50,
        currentY
      );
      currentY -= yOffset;

      firstPage.drawText(
        `Tipo de rosto da Receptora: ${results.receptora_face_type}`,
        {
          x: 50,
          y: currentY,
          size: fontSize,
          font,
          color: rgb(0, 0, 0),
        }
      );
      currentY -= yOffset;
      firstPage.drawText(
        `Tipo de rosto da Doadora: ${results.doadora_face_type}`,
        {
          x: 50,
          y: currentY,
          size: fontSize,
          font,
          color: rgb(0, 0, 0),
        }
      );

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      saveAs(blob, "pareamento_report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Container sx={{ padding: "20px", backgroundColor: "#fff" }}>
      <Typography variant="h4" gutterBottom align="center">
        <strong>Relatório de Pareamento Fenotípico</strong>
      </Typography>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          <strong>Data: {new Date().toLocaleDateString()}</strong>
        </Typography>
      </Box>
      <Card sx={{ p: 2, mt: 3, borderRadius: "16px", boxShadow: 3 }}>
        <Typography variant="h6" gutterBottom>
          Resultados da Similaridade
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" display="flex" alignItems="center">
            <Face3 sx={{ mr: 1 }} /> Similaridade Facial Geral
          </Typography>
          <LinearProgress
            variant="determinate"
            value={results.similarity}
            sx={{ height: 10, borderRadius: 5 }}
          />
          <Typography variant="body2" color="textSecondary">
            {formatPercentage(results.similarity)}
          </Typography>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Exibição de Fotos
          </Typography>
          <Grid container spacing={2}>
            {results.step_images &&
              results.step_images.map((stepImageObj, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ p: 2, borderRadius: "16px", boxShadow: 3 }}>
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Etapa {index + 1}
                      </Typography>
                      {Object.entries(stepImageObj).map(
                        ([key, imgBase64], idx) => (
                          <CardMedia
                            key={idx}
                            component="img"
                            image={`data:image/png;base64,${imgBase64}`}
                            alt={`Passo ${index + 1} - ${key}`}
                            sx={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "200px",
                              objectFit: "contain",
                              borderRadius: "8px",
                              marginTop: 1,
                            }}
                          />
                        )
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Card>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={generatePDF}
          sx={{
            mt: 2,
            borderRadius: "8px",
            padding: "10px 20px",
            backgroundColor: "#f50057",
            "&:hover": {
              backgroundColor: "#c51162",
              transition: "background-color 0.3s ease",
            },
          }}
        >
          Baixar Relatório em PDF
        </Button>
      </Box>
    </Container>
  );
};

export default Relatoriorapido;
