import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import logo from "../assets/logo.png";
import slogan from "../assets/slogan.png";
import brasil from "../assets/brasil.png";
import uniao from "../assets/uniao.png";
import portugal from "../assets/portugal.png";
import "../App.css";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const Flags = () => (
  <div className="flags-container">
    <img src={brasil} alt="Brasil" className="flag-imgb" />
    <img src={uniao} alt="União Europeia" className="flag-img" />
    <img src={portugal} alt="Portugal" className="flag-img" />
  </div>
);

function LoginPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard");
      }
    });

    window.addEventListener("beforeunload", clearSession);
    return () => {
      unsubscribe();
      window.removeEventListener("beforeunload", clearSession);
    };
  }, [navigate]);

  const clearSession = () => {
    localStorage.clear();
  };

  const handleLoginFormSubmit = async (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;

    if (!username.trim() || !password.trim()) {
      setErrorMessage("Por favor, preencha ambos os campos: usuário e senha.");
      return;
    }

    setIsLoading(true);

    const auth = getAuth();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      const user = userCredential.user;

      try {
        const idToken = await user.getIdToken(true);
        const response = await axios.post(
          "https://genematch.app/api/login",
          {},
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (response.data.success) {
          localStorage.setItem("jwtToken", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("user_name", response.data.user.name);
          localStorage.setItem("user_email", response.data.user.email);
          localStorage.setItem("empresaId", response.data.empresaId);
          navigate("/dashboard");
        } else {
          setErrorMessage("Autenticação falhou. Por favor, tente novamente.");
        }
      } catch (serverError) {
        console.error("Erro na autenticação do servidor:", serverError);
        setErrorMessage("Erro ao conectar com o servidor. Tente novamente mais tarde.");
      }
    } catch (authError) {
      console.error("Erro de autenticação:", authError);
      setErrorMessage("Usuário ou senha inválidos. Por favor, tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-main">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo-img" />
        <img src={slogan} alt="slogan" className="slogan-img" />
      </div>

      <Flags />

      <form className="form_main" onSubmit={handleLoginFormSubmit}>
        <p className="heading">Login</p>

        <div className="inputContainer">
          <input
            placeholder="Username"
            id="username"
            className="inputField"
            type="text"
            autoComplete="off"
            required
            name="username"
          />
        </div>

        <div className="inputContainer">
          <input
            placeholder="Password"
            id="password"
            className="inputField"
            type="password"
            autoComplete="off"
            required
            name="password"
          />
        </div>

        <button type="submit" id="button" disabled={isLoading}>
          {isLoading ? "Aguarde..." : "Entrar"}
        </button>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="signupContainer">
          <p>Ainda não tem cadastro?</p>
          <button
            type="button"
            className="button-secondary"
            onClick={() => navigate("/register")}
            disabled={isLoading}
          >
            Criar cadastro
          </button>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
