import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Print } from "@mui/icons-material";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const receptorIcon = new L.Icon({
  iconUrl: "https://example.com/receptor-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const donorIcon = new L.Icon({
  iconUrl: "https://example.com/donor-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const PareamentoView = () => {
  const [pareamento, setPareamento] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      setJwtToken(token);
    }
  }, []);

  useEffect(() => {
    const fetchPareamento = async (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const updatedPareamento = event.data.pareamento;
      if (updatedPareamento) {
        const signedReceptoraPhotoURLs = await generateSignedUrls(
          updatedPareamento.receptora.photoURLs
        );
        updatedPareamento.receptora.photoURLs = signedReceptoraPhotoURLs;

        for (const resultado of updatedPareamento.resultados) {
          resultado.detalhes.photoURLs = await generateSignedUrls(
            resultado.detalhes.photoURLs
          );
        }
        setPareamento(updatedPareamento);
      }
    };

    window.addEventListener("message", fetchPareamento);

    return () => {
      window.removeEventListener("message", fetchPareamento);
    };
  }, [jwtToken]);

  const generateSignedUrls = async (urls) => {
    try {
      const response = await axios.post(
        "https://genematch.app/api/generatemodal-signed-urls",
        { urls },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );
      return response.data.signedUrls;
    } catch (error) {
      console.error("Erro ao gerar URLs assinadas:", error);
      return urls;
    }
  };

  if (!pareamento) {
    return <Typography>Loading...</Typography>;
  }

  const bestMatch = pareamento.resultados?.reduce(
    (max, item) =>
      item.similaridade_contorno_face > max.similaridade_contorno_face
        ? item
        : max,
    pareamento.resultados[0]
  );

  const renderDetailsTable = (details) => (
    <TableContainer component={Paper} sx={{ mb: 2 }}>
      <Table size="small">
        <TableBody>
          {Object.keys(details).map((key) => {
            const value = details[key];
            if (typeof value === "object" || Array.isArray(value)) {
              return null;
            }
            return (
              <TableRow key={key}>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    {key.replace(/_/g, " ")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{value}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const latitudeReceptora = pareamento.receptora.latitude || -23.55052;
  const longitudeReceptora = pareamento.receptora.longitude || -46.633308;
  const latitudeDoadora = bestMatch?.detalhes.latitude || -22.9068;
  const longitudeDoadora = bestMatch?.detalhes.longitude || -43.1729;

  const isValidCoordinatesReceptora =
    !isNaN(latitudeReceptora) && !isNaN(longitudeReceptora);
  const isValidCoordinatesDoadora =
    !isNaN(latitudeDoadora) && !isNaN(longitudeDoadora);

  const populacaoDoadora = 6700000;
  const nascidosVivosAtuais = 5;
  const maxNascidosVivos = Math.floor(populacaoDoadora / 20000) * 2;
  const nascidosVivosRestantes = maxNascidosVivos - nascidosVivosAtuais;

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h5" gutterBottom>
                    Dados da Receptora
                  </Typography>
                  {pareamento.receptora.photoURLs &&
                    pareamento.receptora.photoURLs.length > 0 && (
                      <Card
                        sx={{
                          maxWidth: 100,
                          maxHeight: 100,
                          borderRadius: 2,
                          overflow: "hidden",
                          ml: 2,
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={pareamento.receptora.photoURLs[0]}
                          alt={`Foto da receptora ${pareamento.receptora.name}`}
                          sx={{
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Card>
                    )}
                </Box>
                <Divider sx={{ mb: 2 }} />
                {renderDetailsTable(pareamento.receptora)}
              </CardContent>
            </Card>
            {isValidCoordinatesReceptora && (
              <Card sx={{ mt: 3 }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Localização da Receptora
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  <Box
                    sx={{
                      height: "300px",
                      width: "100%",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                  >
                    <MapContainer
                      center={[latitudeReceptora, longitudeReceptora]}
                      zoom={15}
                      style={{ height: "100%", width: "100%" }}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <Marker
                        position={[latitudeReceptora, longitudeReceptora]}
                        icon={receptorIcon}
                      >
                        <Popup>
                          <Box sx={{ textAlign: "center" }}>
                            <Typography variant="body1">
                              {pareamento.receptora.name}
                            </Typography>
                            {pareamento.receptora.photoURLs &&
                              pareamento.receptora.photoURLs.length > 0 && (
                                <img
                                  src={pareamento.receptora.photoURLs[0]}
                                  alt={`Foto da receptora ${pareamento.receptora.name}`}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "8px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                          </Box>
                        </Popup>
                      </Marker>
                      {isValidCoordinatesDoadora && (
                        <>
                          <Marker
                            position={[latitudeDoadora, longitudeDoadora]}
                            icon={donorIcon}
                          >
                            <Popup>
                              <Box sx={{ textAlign: "center" }}>
                                <Typography variant="body1">
                                  {bestMatch?.doadora_nome}
                                </Typography>
                                {bestMatch?.detalhes.photoURLs &&
                                  bestMatch.detalhes.photoURLs.length > 0 && (
                                    <img
                                      src={bestMatch.detalhes.photoURLs[0]}
                                      alt={`Foto da doadora ${bestMatch.doadora_nome}`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "8px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  )}
                              </Box>
                            </Popup>
                          </Marker>
                          <Polyline
                            positions={[
                              [latitudeReceptora, longitudeReceptora],
                              [latitudeDoadora, longitudeDoadora],
                            ]}
                            color="blue"
                          />
                        </>
                      )}
                    </MapContainer>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h5" gutterBottom>
                    Dados da Doadora
                  </Typography>
                  {bestMatch?.detalhes.photoURLs &&
                    bestMatch.detalhes.photoURLs.length > 0 && (
                      <Card
                        sx={{
                          maxWidth: 100,
                          maxHeight: 100,
                          borderRadius: 2,
                          overflow: "hidden",
                          ml: 2,
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={bestMatch.detalhes.photoURLs[0]}
                          alt={`Foto da doadora ${bestMatch.doadora_nome}`}
                          sx={{
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Card>
                    )}
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Doadora: {bestMatch?.doadora_nome}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "green" }}
                >
                  Similaridade: {bestMatch?.similaridade_contorno_face}%
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h5" gutterBottom>
                  Detalhes da Doadora
                </Typography>
                {renderDetailsTable(bestMatch.detalhes)}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ mt: 3 }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Informações sobre Nascidos Vivos
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="body1">
                  População onde a doadora mora: {populacaoDoadora}
                </Typography>
                <Typography variant="body1">
                  Nascidos Vivos da Doadora: {nascidosVivosAtuais}
                </Typography>
                <Typography variant="body1">
                  Máximo permitido: {maxNascidosVivos}
                </Typography>
                {nascidosVivosAtuais > maxNascidosVivos ? (
                  <Typography variant="body1" color="error">
                    A doadora excedeu o limite de nascidos vivos permitidos.
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    A doadora está dentro do limite permitido.
                  </Typography>
                )}
                <Typography variant="body1">
                  Nascidos vivos restantes permitidos:{" "}
                  {nascidosVivosRestantes >= 0 ? nascidosVivosRestantes : 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Print />}
          sx={{ mt: 3 }}
        >
          Imprimir
        </Button>
      </Card>
    </Box>
  );
};

export default PareamentoView;
