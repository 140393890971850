import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  CardMedia,
  Card,
  Button,
  TextField,
  Divider,
  Paper,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Snackbar,
} from "@mui/material";
import {
  Close,
  Edit,
  Print,
  Visibility,
  AttachFile,
  Delete,
  Save,
  Favorite,
  FavoriteBorder,
  ExpandMore,
  Cancel,
  Info,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import FormOptions from "./FormOptions";
import { FaInfoCircle, FaCut } from "react-icons/fa";
import { generateMedicalReport } from './GenerateMedicalReport';
import TransferModal from './TransferModal';

const DetailsModal = ({ open, onClose, recipient, jwtToken }) => {
  const navigate = useNavigate();
  const [updatedRecipient, setUpdatedRecipient] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [examsOpen, setExamsOpen] = useState(false);
  const [healthQuestionsOpen, setHealthQuestionsOpen] = useState(false);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [selectedDonor, setSelectedDonor] = useState(null);
  const [donorOocytes, setDonorOocytes] = useState(0);
  const [expanded, setExpanded] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentBestMatch, setCurrentBestMatch] = useState(null);
  const [selectedPareamentoIndex, setSelectedPareamentoIndex] = useState(null);
  const [selectedResultadoIndex, setSelectedResultadoIndex] = useState(null);
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [currentReason, setCurrentReason] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelIndexes, setCancelIndexes] = useState({ pareamentoIndex: null, resultadoIndex: null });

  useEffect(() => {
    const revalidateImages = async () => {
      if (recipient) {
        const signedPareamentos = await Promise.all(
          (recipient.pareamentos || []).map(async (pareamento) => {
            const signedReceptoraURLs = await generateSignedUrls(
              pareamento.receptora?.photoURLs || []
            );
            const signedResultados = await Promise.all(
              (pareamento.resultados || []).map(async (resultado) => {
                const signedDoadoraURLs = await generateSignedUrls(
                  resultado.detalhes?.photoURLs || []
                );
                return {
                  ...resultado,
                  detalhes: {
                    ...resultado.detalhes,
                    photoURLs: signedDoadoraURLs,
                  },
                };
              })
            );
            return {
              ...pareamento,
              receptora: {
                ...pareamento.receptora,
                photoURLs: signedReceptoraURLs,
              },
              resultados: signedResultados,
            };
          })
        );
        setUpdatedRecipient({
          ...recipient,
          pareamentos: signedPareamentos,
        });
      }
    };
    revalidateImages();
  }, [recipient]);

  const generateSignedUrls = async (urls) => {
    try {
      const response = await axios.post(
        "https://genematch.app/api/generate-signed-urls",
        { urls },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      return response.data.signedUrls;
    } catch (error) {
      console.error("Erro ao gerar URLs assinadas:", error);
      return urls;
    }
  };

  const fetchDonorOocytes = async (donorId) => {
    try {
      const response = await axios.get(
        `https://genematch.app/api/get-donor-oocytes/${donorId}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setDonorOocytes(response.data.oocytes);
    } catch (error) {
      console.error("Erro ao buscar quantidade de óvulos da doadora:", error);
    }
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleSave = async () => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) {
        console.error("JWT Token não encontrado.");
        return;
      }

      const decodedToken = jwtDecode(jwtToken);
      const empresaId = decodedToken.empresaId;

      if (!empresaId) {
        console.error("empresaId está vazio ou indefinido.");
        return;
      }

      const photoURLs = (updatedRecipient.photoURLs || []).map((url) => {
        if (url.includes("Expires") && url.includes("GoogleAccessId")) {
          const cleanUrl = url.split("?")[0];
          return cleanUrl;
        }
        return url;
      });

      const payload = {
        Empresa: updatedRecipient.Empresa,
        altura: updatedRecipient.altura,
        cor_da_pele: updatedRecipient.cor_da_pele,
        cor_dos_olhos: updatedRecipient.cor_dos_olhos,
        cor_natural_do_cabelo: updatedRecipient.cor_natural_do_cabelo,
        cpf: updatedRecipient.cpf,
        data_de_nascimento: updatedRecipient.data_de_nascimento,
        email: updatedRecipient.email,
        endereco: updatedRecipient.endereco,
        id: updatedRecipient.id,
        idade: updatedRecipient.idade,
        imc: updatedRecipient.imc,
        n_do_prontuario: updatedRecipient.n_do_prontuario,
        name: updatedRecipient.name,
        observacoes: updatedRecipient.observacoes,
        pais: updatedRecipient.pais,
        pesokg: updatedRecipient.pesokg,
        photoURLs: photoURLs,
        raca: updatedRecipient.raca,
        specific_landmarks: updatedRecipient.specific_landmarks,
        telefone: updatedRecipient.telefone,
        tipo_natural_do_cabelo: updatedRecipient.tipo_natural_do_cabelo,
        tipo_sanguineo: updatedRecipient.tipo_sanguineo,
        examURLs: updatedRecipient.examURLs,
        pareamentos: updatedRecipient.pareamentos
      };

      const response = await axios.put(
        `https://genematch.app/api/update-receptora/${updatedRecipient.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            empresaId: empresaId,
          },
        }
      );

      setEditMode(false);
      setUpdatedRecipient(response.data.data);

      onClose();
    } catch (error) {
      console.error("Erro ao salvar as alterações:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) {
        console.error("JWT Token não encontrado.");
        return;
      }

      const decodedToken = jwtDecode(jwtToken);
      const empresaId = decodedToken.empresaId;

      if (!empresaId) {
        console.error("empresaId está vazio ou indefinido.");
        return;
      }

      await axios.delete(
        `https://genematch.app/api/delete-receptora/${updatedRecipient.id}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            empresaId: empresaId,
          },
        }
      );
      onClose();
      navigate("/recipients");
    } catch (error) {
      console.error("Erro ao excluir o cadastro:", error);
    }
  };

  const openConfirmDelete = () => {
    setConfirmDeleteOpen(true);
  };

  const closeConfirmDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const handleExamsOpen = () => {
    setExamsOpen(true);
  };

  const handleCloseExams = () => {
    setExamsOpen(false);
  };

  const handleHealthQuestionsOpen = () => {
    setHealthQuestionsOpen(true);
  };

  const handleCloseHealthQuestions = () => {
    setHealthQuestionsOpen(false);
  };

  const handleView = async (pareamento) => {
    try {
      const signedReceptoraUrls = await generateSignedUrls(
        pareamento.receptora?.photoURLs || []
      );
      const signedDoadoraUrls = await Promise.all(
        (pareamento.resultados || []).map((resultado) =>
          generateSignedUrls(resultado.detalhes?.photoURLs || [])
        )
      );

      const decodedReceptoraUrls = signedReceptoraUrls.map((url) =>
        decodeURIComponent(url)
      );
      const decodedDoadoraUrls = signedDoadoraUrls
        .flat()
        .map((url) => decodeURIComponent(url));

      const newTab = window.open("/pareamento-view", "_blank");
      newTab.onload = function () {
        newTab.postMessage(
          {
            pareamento: {
              ...pareamento,
              receptora: {
                ...pareamento.receptora,
                photoURLs: decodedReceptoraUrls,
              },
              resultados: pareamento.resultados.map((resultado, index) => ({
                ...resultado,
                detalhes: {
                  ...resultado.detalhes,
                  photoURLs: decodedDoadoraUrls[index],
                },
              })),
            },
          },
          window.location.origin
        );
      };
    } catch (error) {
      console.error("Falha ao visualizar os detalhes do pareamento:", error);
    }
  };

  const handlePrintHealthQuestions = async () => {
    try {
      const healthQuestions = Object.entries(updatedRecipient.healthAnswers || {}).map(([question, answer]) => ({
        question,
        answer,
      }));
      await generateMedicalReport(updatedRecipient.name, healthQuestions);
    } catch (error) {
      console.error("An error occurred while printing: ", error);
    }
  };

  const handleOpenTransferModal = async (donor, pareamentoIndex, resultadoIndex) => {
    console.log("Opening transfer modal for donor:", donor, "Pareamento Index:", pareamentoIndex, "Resultado Index:", resultadoIndex);

    // Verifique se os índices estão sendo passados corretamente
    if (resultadoIndex === undefined || pareamentoIndex === undefined) {
      console.error("Índices não definidos. Por favor, certifique-se de que estão sendo definidos corretamente.");
      return;
    }

    const donorWithId = { ...donor, id: donor.name };
    setSelectedDonor(donorWithId);
    setSelectedPareamentoIndex(pareamentoIndex);
    setSelectedResultadoIndex(resultadoIndex);

    try {
      await fetchDonorOocytes(donorWithId.id);
    } catch (error) {
      console.error("Erro ao buscar quantidade de óvulos da doadora:", error);
    }
    setTransferModalOpen(true);
  };


  const handleTransferClick = (bestMatch, pareamentoIndex, resultadoIndex) => {
    const resultado = updatedRecipient.pareamentos[pareamentoIndex].resultados[resultadoIndex];
    const inTransfer = resultado.detalhes.ovulos_recebidos?.some(
      (ovulo) => ovulo.status === "Em transferência"
    );

    if (inTransfer) {
      setSnackbarMessage("Não é possível transferir. Já existe uma transferência em andamento.");
      setSnackbarOpen(true);
    } else {
      if (bestMatch && bestMatch.detalhes) {
        handleOpenTransferModal(bestMatch.detalhes, pareamentoIndex, resultadoIndex);
      } else {
        console.error("Detalhes do melhor match são indefinidos ou estão faltando nome:", bestMatch);
      }
    }
  };

  const handleCloseTransferModal = () => {
    setTransferModalOpen(false);
    setSelectedDonor(null);
    setDonorOocytes(0);
  };

  const handleExpandClick = (index) => {
    setExpanded(expanded === index ? null : index);
  };
  const handleOpenCancelDialog = (pareamentoIndex, resultadoIndex) => {
    setSelectedPareamentoIndex(pareamentoIndex);
    setSelectedResultadoIndex(resultadoIndex);
    setCancelDialogOpen(true);
  };
  const handleCancelTransfer = async () => {
    try {
      const decodedToken = jwtDecode(jwtToken);
      const empresaId = decodedToken.empresaId;

      const payload = {
        recipientId: updatedRecipient.id,
        pareamentoIndex: selectedPareamentoIndex,
        resultadoIndex: selectedResultadoIndex,
        cancelReason: cancelReason,
      };

      const response = await axios.post('https://genematch.app/api/cancel-transfer', payload, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
          empresaId: empresaId,
        },
      });

      if (response.data.success) {
        setSnackbarMessage("Transferência cancelada com sucesso.");
      } else {
        setSnackbarMessage("Erro ao cancelar a transferência.");
      }
      setSnackbarOpen(true);
      setCancelDialogOpen(false);
      setCancelReason("");
    } catch (error) {
      console.error("Erro ao cancelar a transferência:", error.response?.data || error.message);
      setSnackbarMessage("Erro ao cancelar a transferência.");
      setSnackbarOpen(true);
    }
  };
  
  const handleOpenReasonDialog = (reason) => {
    // Verifica se o motivo é válido antes de abrir o diálogo
    if (reason) {
      setCurrentReason(reason);
      setReasonDialogOpen(true);
    } else {
      console.warn("Motivo de cancelamento não encontrado");
    }
  };

  
  return (
    <>
      <Modal open={open} onClose={onClose} closeAfterTransition>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 2000,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "90vh",
            overflowY: "auto",
            fontFamily: "Roboto, sans-serif",
          }}
        >
          <Card sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Box
              sx={{
                flex: "0 1 auto",
                overflow: "visible",
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Detalhes do Cadastro
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ flex: "0 1 auto", overflow: "visible" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  {updatedRecipient?.photoURLs &&
                    updatedRecipient.photoURLs.length > 0 && (
                      <CardMedia
                        component="img"
                        image={updatedRecipient.photoURLs[0]}
                        alt={`Foto de ${updatedRecipient.name}`}
                        sx={{
                          width: "100%",
                          height: "auto",
                          borderRadius: 2,
                          mb: 2,
                          boxShadow: 1,
                        }}
                      />
                    )}
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AttachFile />}
                    fullWidth
                    sx={{ mt: 1 }}
                    onClick={handleExamsOpen}
                  >
                    Outros Exames Anexados
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Edit />}
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={handleEditToggle}
                  >
                    {editMode ? "Cancelar" : "Editar Cadastro"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Visibility />}
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={handleHealthQuestionsOpen}
                  >
                    Ver Perguntas de Saúde
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Print />}
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={handlePrintHealthQuestions}
                  >
                    Imprimir Perguntas de Saúde
                  </Button>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Nome"
                        name="name"
                        value={updatedRecipient?.name || ""}
                        onChange={(e) => {
                          if (editMode) {
                            setUpdatedRecipient({
                              ...updatedRecipient,
                              name: e.target.value,
                            });
                          }
                        }}
                        fullWidth
                        required
                        InputProps={{
                          readOnly: !editMode,
                          style: {
                            backgroundColor: editMode ? "#fff" : "#f0f0f0",
                          },
                        }}
                        variant={editMode ? "outlined" : "filled"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth required>
                        <InputLabel>Cor dos Olhos</InputLabel>
                        <Select
                          name="cor_dos_olhos"
                          value={updatedRecipient?.cor_dos_olhos || ""}
                          onChange={(e) => {
                            if (editMode) {
                              setUpdatedRecipient({
                                ...updatedRecipient,
                                cor_dos_olhos: e.target.value,
                              });
                            }
                          }}
                          inputProps={{
                            style: {
                              backgroundColor: editMode ? "#fff" : "#f0f0f0",
                            },
                          }}
                        >
                          {FormOptions.eyeColors.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <FaInfoCircle
                          style={{
                            marginRight: "8px",
                            color: "#1976d2",
                            fontSize: "1.2rem",
                          }}
                        />
                        <MuiLink
                          href="https://pt.wikipedia.org/wiki/Cor_dos_olhos"
                          target="_blank"
                          rel="noopener"
                          sx={{
                            textDecoration: "none",
                            color: "#1976d2",
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                          }}
                        >
                          Sobre a Cor dos Olhos
                        </MuiLink>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth required>
                        <InputLabel>Raça</InputLabel>
                        <Select
                          name="raca"
                          value={updatedRecipient?.raca || ""}
                          onChange={(e) => {
                            if (editMode) {
                              setUpdatedRecipient({
                                ...updatedRecipient,
                                raca: e.target.value,
                              });
                            }
                          }}
                          inputProps={{
                            style: {
                              backgroundColor: editMode ? "#fff" : "#f0f0f0",
                            },
                          }}
                        >
                          {FormOptions.races.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth required>
                        <InputLabel>Cor Natural do Cabelo</InputLabel>
                        <Select
                          name="cor_natural_do_cabelo"
                          value={updatedRecipient?.cor_natural_do_cabelo || ""}
                          onChange={(e) => {
                            if (editMode) {
                              setUpdatedRecipient({
                                ...updatedRecipient,
                                cor_natural_do_cabelo: e.target.value,
                              });
                            }
                          }}
                          inputProps={{
                            style: {
                              backgroundColor: editMode ? "#fff" : "#f0f0f0",
                            },
                          }}
                        >
                          {FormOptions.hairColors.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <FaCut
                          style={{
                            marginRight: "8px",
                            color: "#1976d2",
                            fontSize: "1.2rem",
                          }}
                        />
                        <MuiLink
                          href="https://pt.wikipedia.org/wiki/Cor_dos_cabelos"
                          target="_blank"
                          rel="noopener"
                          sx={{
                            textDecoration: "none",
                            color: "#1976d2",
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                          }}
                        >
                          Sobre cores de cabelo
                        </MuiLink>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth required>
                        <InputLabel>Cor da Pele</InputLabel>
                        <Select
                          name="cor_da_pele"
                          value={updatedRecipient?.cor_da_pele || ""}
                          onChange={(e) => {
                            if (editMode) {
                              setUpdatedRecipient({
                                ...updatedRecipient,
                                cor_da_pele: e.target.value,
                              });
                            }
                          }}
                          inputProps={{
                            style: {
                              backgroundColor: editMode ? "#fff" : "#f0f0f0",
                            },
                          }}
                        >
                          {FormOptions.skinColors.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <FaInfoCircle
                          style={{
                            marginRight: "8px",
                            color: "#1976d2",
                            fontSize: "1.2rem",
                          }}
                        />
                        <MuiLink
                          href="https://pt.wikipedia.org/wiki/Escala_de_Fitzpatrick"
                          target="_blank"
                          rel="noopener"
                          sx={{
                            textDecoration: "none",
                            color: "#1976d2",
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                          }}
                        >
                          Sobre a Escala de Fitzpatrick
                        </MuiLink>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth required>
                        <InputLabel>Tipo Natural do Cabelo</InputLabel>
                        <Select
                          name="tipo_natural_do_cabelo"
                          value={updatedRecipient?.tipo_natural_do_cabelo || ""}
                          onChange={(e) => {
                            if (editMode) {
                              setUpdatedRecipient({
                                ...updatedRecipient,
                                tipo_natural_do_cabelo: e.target.value,
                              });
                            }
                          }}
                          inputProps={{
                            style: {
                              backgroundColor: editMode ? "#fff" : "#f0f0f0",
                            },
                          }}
                        >
                          {FormOptions.hairTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Idade"
                        name="idade"
                        type="number"
                        inputProps={{ min: "0", max: "99" }}
                        value={updatedRecipient?.idade || ""}
                        onChange={(e) => {
                          if (editMode) {
                            setUpdatedRecipient({
                              ...updatedRecipient,
                              idade: e.target.value,
                            });
                          }
                        }}
                        fullWidth
                        required
                        InputProps={{
                          readOnly: !editMode,
                          style: {
                            backgroundColor: editMode ? "#fff" : "#f0f0f0",
                          },
                        }}
                        variant={editMode ? "outlined" : "filled"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth required>
                        <InputLabel>Tipo Sanguíneo</InputLabel>
                        <Select
                          name="tipo_sanguineo"
                          value={updatedRecipient?.tipo_sanguineo || ""}
                          onChange={(e) => {
                            if (editMode) {
                              setUpdatedRecipient({
                                ...updatedRecipient,
                                tipo_sanguineo: e.target.value,
                              });
                            }
                          }}
                          inputProps={{
                            style: {
                              backgroundColor: editMode ? "#fff" : "#f0f0f0",
                            },
                          }}
                        >
                          {FormOptions.bloodTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Altura (em metros)"
                        name="altura"
                        value={updatedRecipient?.altura || ""}
                        onChange={(e) => {
                          if (editMode) {
                            setUpdatedRecipient({
                              ...updatedRecipient,
                              altura: e.target.value,
                            });
                          }
                        }}
                        fullWidth
                        required
                        InputProps={{
                          readOnly: !editMode,
                          style: {
                            backgroundColor: editMode ? "#fff" : "#f0f0f0",
                          },
                        }}
                        variant={editMode ? "outlined" : "filled"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Peso (kg)"
                        name="pesokg"
                        value={updatedRecipient?.pesokg || ""}
                        onChange={(e) => {
                          if (editMode) {
                            setUpdatedRecipient({
                              ...updatedRecipient,
                              pesokg: e.target.value,
                            });
                          }
                        }}
                        fullWidth
                        required
                        InputProps={{
                          readOnly: !editMode,
                          style: {
                            backgroundColor: editMode ? "#fff" : "#f0f0f0",
                          },
                        }}
                        variant={editMode ? "outlined" : "filled"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="IMC"
                        name="imc"
                        value={updatedRecipient?.imc || ""}
                        fullWidth
                        required
                        InputProps={{
                          readOnly: true,
                          style: {
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                        variant="filled"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <FaInfoCircle
                          style={{
                            marginRight: "8px",
                            color: "#1976d2",
                            fontSize: "1.2rem",
                          }}
                        />
                        <MuiLink
                          href="https://pt.wikipedia.org/wiki/%C3%8Dndice_de_massa_corporal"
                          target="_blank"
                          rel="noopener"
                          sx={{
                            textDecoration: "none",
                            color: "#1976d2",
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                          }}
                        >
                          Mais informações sobre IMC
                        </MuiLink>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={updatedRecipient?.email || ""}
                        onChange={(e) => {
                          if (editMode) {
                            setUpdatedRecipient({
                              ...updatedRecipient,
                              email: e.target.value,
                            });
                          }
                        }}
                        fullWidth
                        InputProps={{
                          readOnly: !editMode,
                          style: {
                            backgroundColor: editMode ? "#fff" : "#f0f0f0",
                          },
                        }}
                        variant={editMode ? "outlined" : "filled"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Nº do Prontuário"
                        name="n_do_prontuario"
                        value={updatedRecipient?.n_do_prontuario || ""}
                        onChange={(e) => {
                          if (editMode) {
                            setUpdatedRecipient({
                              ...updatedRecipient,
                              n_do_prontuario: e.target.value,
                            });
                          }
                        }}
                        fullWidth
                        InputProps={{
                          readOnly: !editMode,
                          style: {
                            backgroundColor: editMode ? "#fff" : "#f0f0f0",
                          },
                        }}
                        variant={editMode ? "outlined" : "filled"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Observações"
                        name="observacoes"
                        value={updatedRecipient?.observacoes || ""}
                        onChange={(e) => {
                          if (editMode) {
                            setUpdatedRecipient({
                              ...updatedRecipient,
                              observacoes: e.target.value,
                            });
                          }
                        }}
                        fullWidth
                        multiline
                        rows={4}
                        InputProps={{
                          readOnly: !editMode,
                          style: {
                            backgroundColor: editMode ? "#fff" : "#f0f0f0",
                          },
                        }}
                        variant={editMode ? "outlined" : "filled"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                flex: "1 1 auto",
                overflowY: "auto",
                bgcolor: "#f9f9f9",
                p: 2,
                borderRadius: 2,
                mt: 2,
                maxHeight: "40vh",
                "@media (max-width: 600px)": {
                  maxHeight: "30vh",
                },
              }}
            >
              <Typography variant="h6" gutterBottom>
                Histórico de Pareamento
              </Typography>
              <Paper
                sx={{
                  backgroundColor: "#ffffff",
                  padding: 2,
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <TableContainer>
                  <Table size="small" aria-label="Histórico de Pareamento">
                    <TableHead>
                      <TableRow>
                        <TableCell>Data do Pareamento</TableCell>
                        <TableCell>Nome da Receptora</TableCell>
                        <TableCell>Nome da Doadora</TableCell>
                        <TableCell>Ovulos Recebidos</TableCell>
                        <TableCell>Similaridade</TableCell>
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {updatedRecipient?.pareamentos && updatedRecipient.pareamentos.length > 0 ? (
                        updatedRecipient.pareamentos.map((pareamento, index) => (
                          pareamento.resultados.map((resultado, resultadoIndex) => {
                            const ovulosRecebidos = resultado.detalhes?.ovulos_recebidos;

                            return (
                              <React.Fragment key={`${index}-${resultadoIndex}`}>
                                <TableRow>
                                  <TableCell>
                                    {new Date(pareamento.data_pareamento).toLocaleString()}
                                  </TableCell>
                                  <TableCell>
                                    {pareamento.receptora?.name || updatedRecipient.name}
                                  </TableCell>
                                  <TableCell>{resultado.doadora_nome}</TableCell>
                                  <TableCell>
                                    {ovulosRecebidos ? (
                                      <IconButton
                                        onClick={() => handleExpandClick(index)}
                                        aria-expanded={expanded === index}
                                        aria-label="show more"
                                      >
                                        {ovulosRecebidos.length} <ExpandMore />
                                      </IconButton>
                                    ) : (
                                      "N/A"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {resultado.similaridade_contorno_face}%
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title="Visualizar">
                                      <IconButton
                                        size="small"
                                        onClick={() => handleView(pareamento)}
                                      >
                                        <Visibility sx={{ color: "blue" }} />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Imprimir">
                                      <IconButton size="small">
                                        <Print sx={{ color: "green" }} />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Iniciar Transferência">
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          const ovulosRecebidos = resultado.detalhes.ovulos_recebidos || [];
                                          if (ovulosRecebidos.some((ovulo) => ovulo.status === "Em transferência")) {
                                            setSnackbarMessage("Não é possível transferir. Já existe uma transferência em andamento.");
                                            setSnackbarOpen(true);
                                          } else {
                                            handleOpenTransferModal(resultado.detalhes, index, resultadoIndex);
                                          }
                                        }}
                                      >
                                        {resultado.detalhes.ovulos_recebidos?.some((ovulo) => ovulo.status === "Em transferência") ? (
                                          <Favorite
                                            sx={{
                                              color: "yellow",
                                              animation: "blinker 1.5s linear infinite",
                                              "@keyframes blinker": {
                                                "50%": { opacity: 0 },
                                              },
                                            }}
                                          />
                                        ) : (
                                          <FavoriteBorder sx={{ color: "red" }} />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                    {resultado.detalhes.ovulos_recebidos?.some((ovulo) => ovulo.status === "Em transferência") && (
                                      <Tooltip title="Cancelar Transferência">
                                        <IconButton
                                          size="small"
                                          color="error"
                                          onClick={() => handleOpenCancelDialog(index, resultadoIndex)}
                                          sx={{
                                            backgroundColor: "#f44336",
                                            color: "#fff",
                                            '&:hover': {
                                              backgroundColor: "#d32f2f",
                                            },
                                            marginLeft: 1,
                                          }}
                                        >
                                          <Cancel />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                </TableRow>
                                
                               <TableRow>
                                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                      <Box margin={1}>
                                        <Typography variant="subtitle1" gutterBottom component="div">
                                          Detalhes da Transferência:
                                        </Typography>
                                        {ovulosRecebidos?.map((ovulo, i) => (
                                          <React.Fragment key={i}>
                                            <Typography variant="body2" gutterBottom component="div">
                                              Data de Transferência: {ovulo.transferDate || "N/A"}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom component="div">
                                              Status: {ovulo.status || "N/A"}
                                            </Typography>
                                                  {ovulo.status === "Cancelado" && ovulo.cancelReason && (
                                                    <Tooltip title="Ver Motivo do Cancelamento">
                                                      <IconButton
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => handleOpenReasonDialog(ovulo.cancelReason)}
                                                      >
                                                  <Info />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            Nenhum histórico de pareamento encontrado.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>

                  </Table>
                </TableContainer>
              </Paper>

            </Box>
           
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={2} mt={2}>
              <Button
                onClick={onClose}
                variant="contained"
                color="primary"
                startIcon={<Close />}
                sx={{ mb: { xs: 1, sm: 0 } }}
              >
                Fechar
              </Button>
              {editMode && (
                <Box display="flex" gap={2} flexWrap="wrap">
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    color="secondary"
                    startIcon={<Save />}
                    sx={{ mb: { xs: 1, sm: 0 } }}
                  >
                    Salvar
                  </Button>
                  <Button
                    onClick={openConfirmDelete}
                    variant="contained"
                    color="error"
                    startIcon={<Delete />}
                  >
                    Excluir
                  </Button>
                </Box>
              )}
            </Box>
                                        </Card>
                                      </Box>
      </Modal>
      <Dialog open={reasonDialogOpen} onClose={() => setReasonDialogOpen(false)}>
        <DialogTitle>Motivo do Cancelamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentReason || "Motivo não disponível."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReasonDialogOpen(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={cancelDialogOpen} onClose={() => setCancelDialogOpen(false)}>
        <DialogTitle>Cancelar Transferência</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, insira o motivo do cancelamento da transferência.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Motivo"
            type="text"
            fullWidth
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCancelTransfer} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDeleteOpen}
        onClose={closeConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza de que deseja excluir este cadastro? Todas as fotos e
            registros associados serão permanentemente excluídos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDelete} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              closeConfirmDelete();
              handleDelete();
            }}
            color="error"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={examsOpen}
        onClose={handleCloseExams}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Outros Exames Anexados</DialogTitle>
        <DialogContent>
          <List>
            {updatedRecipient?.examURLs &&
              updatedRecipient.examURLs.map((url, index) => {
                const filename = decodeURIComponent(
                  url.split("/").pop().split("?")[0]
                );
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      component="a"
                      href={url}
                      target="_blank"
                      rel="noopener"
                    >
                      <ListItemText primary={filename} />
                    </ListItem>
                    {index < updatedRecipient.examURLs.length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExams} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={healthQuestionsOpen}
        onClose={handleCloseHealthQuestions}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Perguntas de Saúde</DialogTitle>
        <DialogContent dividers>
          {updatedRecipient?.healthAnswers &&
            Object.entries(updatedRecipient.healthAnswers).map(
              ([question, answer], index) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 2,
                      p: 2,
                      backgroundColor: "#f9f9f9",
                      borderRadius: 2,
                      boxShadow: 1,
                    }}
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      {question}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {answer}
                    </Typography>
                  </Box>
                  {index <
                    Object.entries(updatedRecipient.healthAnswers).length - 1 && (
                    <Divider />
                  )}
                </React.Fragment>
              )
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHealthQuestions} color="primary">
            Fechar
          </Button>
          <Button onClick={handlePrintHealthQuestions} color="secondary" startIcon={<Print />}>
            Imprimir Perguntas de Saúde
          </Button>
        </DialogActions>
      </Dialog>
      {selectedDonor && (
      <TransferModal
        open={transferModalOpen}
        onClose={handleCloseTransferModal}
        donor={selectedDonor}
        recipient={updatedRecipient}
        jwtToken={jwtToken}
        pareamentoIndex={selectedPareamentoIndex}
        selectedResultadoIndex={selectedResultadoIndex} 
      />

      )}

    </>
  );
};

export default DetailsModal;
