import React, { useState } from "react";
import { Box, TextField, Button, Typography, Divider } from "@mui/material";

const CreateUser = ({ empresaId }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    role: "",
  });

  const handleUserChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateUser = () => {
    // Adicione a lógica para criar um usuário aqui
    console.log("User Details:", userDetails);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Criar Usuário
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <TextField
        label="Nome"
        name="name"
        value={userDetails.name}
        onChange={handleUserChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={userDetails.email}
        onChange={handleUserChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Cargo"
        name="role"
        value={userDetails.role}
        onChange={handleUserChange}
        fullWidth
        margin="normal"
      />
      <Button
        onClick={handleCreateUser}
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Criar Usuário
      </Button>
    </Box>
  );
};

export default CreateUser;
