import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { jwtDecode } from "jwt-decode";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Toolbar,
  AppBar,
  CssBaseline,
  Divider,
  Avatar,
  Tooltip,
  Badge,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {
  Home as HomeIcon,
  PersonAdd as PersonAddIcon,
  LocalHospital as VialIcon,
  Science as FlaskIcon,
  ExitToApp as ExitToAppIcon,
  Notifications as NotificationsIcon,
  Menu as MenuIcon,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Groups as GroupsIcon,
  Face as FaceIcon,
  CalendarToday as CalendarTodayIcon,
  PermIdentity as PermIdentityIcon,
  ContactMail as ContactMailIcon,
  Print as PrintIcon,
  FilterList as FilterListIcon,
  SwapHorizontalCircle as SwapHorizontalCircleIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { useTitle } from "../titleContext/TitleContext";
import logo from "../assets/logo.png";
import SettingsModal from "./SettingsModal";
import "./Sidebar.css";
import fontkit from "@pdf-lib/fontkit";
import { getNotifications, markNotificationAsRead } from "./notificationService";
import { PDFDocument, rgb } from "pdf-lib";
const drawerWidth = 280;

const SidebarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const NotificationItem = styled(MenuItem)(({ theme, read }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  backgroundColor: read ? "#f0f0f0" : "white",
}));

const Sidebar = ({ setActiveTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const { title, setTitle } = useTitle();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [empresaId, setEmpresaId] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [filter, setFilter] = useState("new");
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      const decodedEmpresaId = decodedToken.empresaId;
      setEmpresaId(decodedEmpresaId);

      setDrawerOpen(!isMobile);

      const storedUserName = localStorage.getItem("user_name");
      const storedUserEmail = localStorage.getItem("user_email");

      if (storedUserName && storedUserEmail) {
        setUserName(storedUserName);
        setUserEmail(storedUserEmail);
      }

      fetchNotifications(decodedEmpresaId);
    }
  }, [isMobile]);

  const fetchNotifications = async (empresaId) => {
    try {
      const notifications = await getNotifications(empresaId);

      const sortedNotifications = notifications.sort((a, b) => {
        if (a.read === b.read) {
          return new Date(b.timestamp) - new Date(a.timestamp);
        }
        return a.read - b.read;
      });

      setNotifications(sortedNotifications);
      setUnreadCount(
        notifications.filter((notification) => !notification.read).length
      );
    } catch (error) {
      console.error("Erro ao buscar notificações:", error);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        console.error("Erro ao sair:", error);
      });
  };

  const handleSettingsModalToggle = () => {
    setIsSettingsModalVisible(!isSettingsModalVisible);
  };

  const getSelected = (path) => {
    return location.pathname === path;
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationItemClick = (notification) => {
    setSelectedNotification(notification);
    setModalOpen(true);
    markNotificationAsRead(notification.id, empresaId).then(() => {
      fetchNotifications(empresaId);
    });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedNotification(null);
  };

  const handlePrint = async () => {
    const existingPdfBytes = await fetch("/relatoriomedico.pdf").then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);
    const fontBytes = await fetch(
      "https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf"
    ).then((res) => res.arrayBuffer());
    const customFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    if (selectedNotification) {
      firstPage.drawText(
        `Receptora ID: ${selectedNotification.receptora_id}`,
        {
          x: 50,
          y: 700,
          size: 12,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );
      firstPage.drawText(`Nome: ${selectedNotification.receptora_name}`, {
        x: 50,
        y: 680,
        size: 12,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(
        `Correspondência: ${selectedNotification.percentual_correspondencia}%`,
        {
          x: 50,
          y: 660,
          size: 12,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );
      firstPage.drawText(`Doadora ID: ${selectedNotification.donor_id}`, {
        x: 50,
        y: 640,
        size: 12,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(
        `Data: ${formatDate(selectedNotification.timestamp)}`,
        {
          x: 50,
          y: 620,
          size: 12,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );
      firstPage.drawText(
        "Essa correspondência avaliou características fenotípicas das seguintes áreas da face: Cor da pele, cor dos olhos, tipo natural do cabelo.",
        {
          x: 50,
          y: 600,
          size: 12,
          font: customFont,
          color: rgb(0, 0, 0),
          maxWidth: 500,
        }
      );

      firstPage.drawText("������", {
        x: 30,
        y: 700,
        size: 12,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText("������", {
        x: 30,
        y: 680,
        size: 12,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText("������", {
        x: 30,
        y: 640,
        size: 12,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText("������", {
        x: 30,
        y: 620,
        size: 12,
        font: customFont,
        color: rgb(0, 0, 0),
      });
    }

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "relatoriomedico.pdf";
    link.click();
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const filteredNotifications = notifications.filter((notification) =>
    filter === "new" ? !notification.read : notification.read
  );

  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchorEl(null);
  };

  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
    handleFilterMenuClose();
  };

  const handleTransferUpdate = (notificationId, response) => {};

  const handleTransferResponse = (notification, evolved) => {};

  return (
    <SidebarContainer>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: "#840fb5" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, display: { sm: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Tooltip title="Notificações">
            <IconButton color="inherit" onClick={handleNotificationClick}>
              <Badge badgeContent={unreadCount} color="error">
                <NotificationsIcon sx={{ color: "#fff" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Menu">
            <IconButton color="inherit" onClick={handleSettingsModalToggle}>
              <SettingsIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          width: 130,
          flexShrink: 0,
          "& .MuiDrawer-paper": { width: drawerWidth, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <Box sx={{ display: "flex", flexDirection: "column"}}>
          <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <img
              src={logo}
              alt="Logo"
              className="logo-spin"
              style={{ width: "50%", height: "auto" }}
            />
          </Box>
          <Divider />
          <UserBox>
            <Avatar sx={{ mr: 2 }}>{userName.charAt(0)}</Avatar>
            <Box>
              <Typography variant="subtitle1">{userName}</Typography>
              <Typography variant="body2" color="textSecondary">
                {userEmail}
              </Typography>
            </Box>
          </UserBox>
          <Divider />
          <Box sx={{ flexGrow: 1 }}>
            <List>
              <ListItem
                button
                component={Link}
                to="/dashboard"
                onClick={() => {
                  setActiveTab("home");
                  setTitle("Dashboard");
                }}
                selected={getSelected("/dashboard")}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/DonorRegistration"
                onClick={() => {
                  setActiveTab("donorRegistration");
                  setTitle("Cadastro e Consulta de Doadoras");
                }}
                selected={getSelected("/DonorRegistration")}
              >
                <ListItemIcon>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText primary="Cadastro e Consulta de Doadoras" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/RecipientRegistration"
                onClick={() => {
                  setActiveTab("recipientRegistration");
                  setTitle("Cadastro e Consulta de Receptoras");
                }}
                selected={getSelected("/RecipientRegistration")}
              >
                <ListItemIcon>
                  <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="Cadastro e Consulta de Receptoras" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/Pairings"
                onClick={() => {
                  setActiveTab("pairings");
                  setTitle("Pareamento Fenotípico");
                }}
                selected={getSelected("/Pairings")}
              >
                <ListItemIcon>
                  <VialIcon />
                </ListItemIcon>
                <ListItemText primary="Pareamento Fenotípico" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/BancoDeDoadoras"
                onClick={() => {
                  setActiveTab("bancoDeDoadoras");
                  setTitle("Banco de Doadoras");
                }}
                selected={getSelected("/BancoDeDoadoras")}
              >
                <ListItemIcon>
                  <FlaskIcon />
                </ListItemIcon>
                <ListItemText primary="Banco de Doadoras" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/ImageUploader"
                onClick={() => {
                  setActiveTab("imageUploader");
                  setTitle("Pareamento Rápido");
                }}
                selected={getSelected("/ImageUploader")}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Pareamento Rápido" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/BancoNacionalDeDoadoras"
                onClick={() => {
                  setActiveTab("BancoNacionalDeDoadoras");
                  setTitle("Banco Nacional de Doadoras");
                }}
                selected={getSelected("/BancoNacionalDeDoadoras")}
              >
                <ListItemIcon>
                  <FlaskIcon />
                </ListItemIcon>
                <ListItemText primary="Banco Nacional de Doadoras" />
              </ListItem>
            </List>
          </Box>
          <Divider />
          <List>
            <ListItem button onClick={handleSettingsModalToggle}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Menu" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <SettingsModal
        open={isSettingsModalVisible}
        onClose={handleSettingsModalToggle}
        empresaId={empresaId}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationClose}
        PaperProps={{
          style: {
            maxHeight: "80vh",
            width: "clamp(300px, 50vw, 600px)",
          },
        }}
      >
        <MenuItem>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Notificações
          </Typography>
          <IconButton onClick={handleFilterMenuClick}>
            <FilterListIcon />
          </IconButton>
        </MenuItem>
        {filteredNotifications.map((notification, index) => (
          <React.Fragment key={index}>
            <NotificationItem
              onClick={() => handleNotificationItemClick(notification)}
              read={notification.read}
            >
              {notification.tipo === "correspondencia_encontrada" && (
                <>
                  <Box display="flex" alignItems="center">
                    <PermIdentityIcon color="primary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Receptora ID: {notification.receptora_id}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FaceIcon color="secondary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Correspondência: {notification.percentual_correspondencia}%
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ContactMailIcon color="action" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Doadora ID: {notification.donor_id}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <CalendarTodayIcon color="error" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Data: {formatDate(notification.timestamp)}
                    </Typography>
                  </Box>
                </>
              )}
              {notification.tipo === "verificacao_transferencia" && (
                <>
                  <Box display="flex" alignItems="center">
                    <SwapHorizontalCircleIcon color="primary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Transferência de Óvulos
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <PermIdentityIcon color="primary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Receptora ID: {notification.receptora_id}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Dias desde a transferência:{" "}
                      {notification.dias_desde_transferencia}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <CalendarTodayIcon color="error" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Data: {formatDate(notification.timestamp)}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap={1} mt={1}>
                    <Typography variant="body2" color="textSecondary">
                      Essa transferência teve evolução?
                    </Typography>
                    <Box display="flex" gap={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleTransferResponse(notification, true)
                        }
                      >
                        Sim
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          handleTransferResponse(notification, false)
                        }
                      >
                        Não
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
            </NotificationItem>
            {index < filteredNotifications.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Menu>
      <Menu
        anchorEl={filterMenuAnchorEl}
        open={Boolean(filterMenuAnchorEl)}
        onClose={handleFilterMenuClose}
      >
        <MenuItem onClick={() => handleFilterChange("new")}>Novas</MenuItem>
        <MenuItem onClick={() => handleFilterChange("old")}>Antigas</MenuItem>
      </Menu>
      <Dialog open={modalOpen} onClose={handleModalClose} maxWidth="md" fullWidth>
        <DialogTitle>Detalhes da Notificação</DialogTitle>
        <DialogContent>
          {selectedNotification &&
            selectedNotification.tipo === "correspondencia_encontrada" && (
              <>
                <Typography variant="body1" gutterBottom>
                  Essa correspondência avaliou características fenotípicas das
                  seguintes áreas da face: Cor da pele, Cor dos olhos, Tipo
                  Natural do cabelo, Cor natural do cabelo e Raça/Etnia. Para
                  similaridade facial, realize o pareamento completo entre a
                  receptora e a doadora indicada na correspondência.
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" alignItems="center">
                    <PermIdentityIcon color="primary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Receptora ID: {selectedNotification.receptora_id}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FaceIcon color="secondary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Correspondência:{" "}
                      {selectedNotification.percentual_correspondencia}%
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ContactMailIcon color="action" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Doadora ID: {selectedNotification.donor_id}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <CalendarTodayIcon color="error" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Data: {formatDate(selectedNotification.timestamp)}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          {selectedNotification &&
            selectedNotification.tipo === "verificacao_transferencia" && (
              <>
                <Typography variant="body1" gutterBottom>
                  A transferência está pendente há mais de 20 dias. Atualize o
                  status da transferência no Cadastro da Receptora.
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box display="flex" alignItems="center">
                    <SwapHorizontalCircleIcon color="primary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Transferência de Óvulos
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <PermIdentityIcon color="primary" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Receptora ID: {selectedNotification.receptora_id}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Dias desde a transferência:{" "}
                      {selectedNotification.dias_desde_transferencia}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <CalendarTodayIcon color="error" />
                    <Typography variant="body2" color="textSecondary" ml={1}>
                      Data: {formatDate(selectedNotification.timestamp)}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrint} startIcon={<PrintIcon />} color="primary">
            Imprimir
          </Button>
          <Button onClick={handleModalClose} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </SidebarContainer>
  );
};

export default Sidebar;
