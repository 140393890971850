import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyC2FovcLgcxZwjQ2EB6EcgZZqmrrDvvCdY",
  authDomain: "genematch-f8c65.firebaseapp.com",
  projectId: "genematch-f8c65",
  storageBucket: "genematch-f8c65.appspot.com",
  messagingSenderId: "1031151278913",
  appId: "1:1031151278913:web:b8155f631814a47dfadfc0",
  measurementId: "G-LW5CWG9R5R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };

