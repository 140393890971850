import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const TransferModal = ({
  open,
  onClose,
  donor,
  recipient,
  jwtToken,
  pareamentoIndex,
  selectedResultadoIndex,
}) => {
  const [quantity, setQuantity] = useState("");
  const [transferDate, setTransferDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [donorOocytes, setDonorOocytes] = useState(0);

  useEffect(() => {
    const fetchDonorOocytes = async () => {
      try {
        const decodedToken = jwtDecode(jwtToken);
        const empresaId = decodedToken.empresaId;

        console.log("Buscando óvulos da doadora com ID:", donor.id);
        console.log("ID da Empresa:", empresaId);

        const response = await axios.get(
          `https://genematch.app/api/get-donor-oocytes/${donor.id}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              empresaId: empresaId,
            },
          }
        );

        console.log("Dados da resposta:", response.data);

        setDonorOocytes(response.data.oocytes);
      } catch (error) {
        console.error("Erro ao buscar quantidade de óvulos da doadora:", error);
      }
    };

    if (open && donor && donor.id) {
      fetchDonorOocytes();
    }
  }, [open, donor, jwtToken]);

  const handleTransfer = async () => {
    setLoading(true);

    try {
      const decodedToken = jwtDecode(jwtToken);
      const empresaId = decodedToken.empresaId;

      // Validação de entrada
      if (!quantity || isNaN(quantity) || quantity <= 0) {
        alert("Por favor, insira uma quantidade válida.");
        setLoading(false);
        return;
      }

      if (!transferDate) {
        alert("Por favor, selecione uma data de transferência.");
        setLoading(false);
        return;
      }

      // Verifique se os índices estão sendo usados corretamente
      if (pareamentoIndex === undefined || selectedResultadoIndex === undefined) {
        console.error("Índices de pareamento ou resultado não definidos:", pareamentoIndex, selectedResultadoIndex);
        alert("Índices de pareamento ou resultado não definidos.");
        setLoading(false);
        return;
      }

      const payload = {
        donorId: donor.id,
        donorName: donor.name,
        recipientId: recipient.id,
        recipientName: recipient.name,
        quantity: parseInt(quantity, 10),
        transferDate,
        pareamentoIndex,
        selectedResultadoIndex,
      };

      console.log("Payload enviado:", payload);

      const response = await axios.post('https://genematch.app/api/transfer-ovules', payload, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
          empresaId: empresaId,
        },
      });

      setLoading(false);
      alert("Transferência realizada com sucesso!");
      onClose();
    } catch (error) {
      console.error("Erro ao realizar a transferência:", error.response?.data || error.message);
      setLoading(false);
      alert("Erro ao realizar a transferência. Por favor, tente novamente.");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Transferência de Óvulos
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Doadora: {donor.name}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Receptora: {recipient.name}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Quantidade disponível: {donorOocytes}
        </Typography>
        <TextField
          label="Quantidade a transferir"
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        />
        <TextField
          label="Data de Transferência"
          type="date"
          value={transferDate}
          onChange={(e) => setTransferDate(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          onClick={handleTransfer}
          disabled={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          {loading ? "Transferindo..." : "Transferir"}
        </Button>
      </Box>
    </Modal>
  );
};

export default TransferModal;
