import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  where,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from "./firebase";
import Sidebar from "./Sidebar";
import DonorRegistration from "./DonorRegistration";
import RecipientRegistration from "./RecipientRegistration";
import Pairings from "./Pairings";
import BancoDeDoadoras from "./BancoDeDoadoras";

import { Routes, Route } from "react-router-dom"; // Import Routes and Route from react-router-dom
import { jwtDecode } from "jwt-decode";
import {
  Box,
  Container,
  Typography,
  Button,
  Modal,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
} from "@mui/material";
import { FaSearch, FaRedo, FaTimes, FaFemale } from "react-icons/fa";

const BancoNacionalDeDoadoras = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [doadoras, setDoadoras] = useState([]);
  const [searchCorOlhos, setSearchCorOlhos] = useState("");
  const [searchTipoCabelo, setSearchTipoCabelo] = useState("");
  const [searchRaca, setSearchRaca] = useState("");
  const [searchCorCabelo, setSearchCorCabelo] = useState("");
  const [searchCorPele, setSearchCorPele] = useState("");
  const [selectedDoadora, setSelectedDoadora] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [noResultsModal, setNoResultsModal] = useState(false);
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState(false);

  useEffect(() => {
    // Add logic if needed for component mount
  }, []);

  const handleCloseNoResultsModal = () => setNoResultsModal(false);

  const handleCloseBlockedModal = () => setIsBlockedModalOpen(false);

  const searchDoadorasAPI = async (filters) => {
    const jwtToken = localStorage.getItem("jwtToken");

    const response = await fetch(
      "https://genematch.app/api/banco-nacional-de-doadoras/buscar",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(filters),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch donors: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  };

  const handleNewSearch = () => {
    setSearchCorOlhos("");
    setSearchTipoCabelo("");
    setSearchRaca("");
    setSearchCorCabelo("");
    setSearchCorPele("");
    setDoadoras([]);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsSearching(true);

    try {
      const filters = {
        cor_dos_olhos: searchCorOlhos,
        tipo_natural_do_cabelo: searchTipoCabelo,
        raca: searchRaca,
        cor_natural_do_cabelo: searchCorCabelo,
        cor_da_pele: searchCorPele,
      };

      const doadorasData = await searchDoadorasAPI(filters);

      if (doadorasData.length === 0) {
        setNoResultsModal(true);
      }

      setDoadoras(doadorasData);
    } catch (error) {
      // Handle errors silently
    } finally {
      setIsSearching(false);
    }
  };

  const handleImageClick = (doadora) => {
    setSelectedDoadora(doadora);
  };

  const handleCloseModal = () => {
    setSelectedDoadora(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
      <Sidebar setActiveTab={setActiveTab} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="false" sx={{ mt: 2, mb: 4, ml: 2, width: "100%" }}>
          <Routes>
            <Route path="/donorregistration" element={<DonorRegistration />} />
            <Route
              path="/recipientregistration"
              element={<RecipientRegistration />}
            />
            <Route path="/pairings" element={<Pairings />} />
            <Route path="/Banco" element={<BancoNacionalDeDoadoras />} />
            <Route path="/bancodedoadoras" element={<BancoDeDoadoras />} />
          </Routes>
          <Card sx={{ p: 3, mb: 2, borderRadius: "16px", boxShadow: 3 }}>
            <Typography variant="h4" gutterBottom>
              Banco Nacional de Doadoras
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <form onSubmit={handleSearch}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Cor da Pele</InputLabel>
                    <Select
                      value={searchCorPele}
                      onChange={(e) => setSearchCorPele(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      <MenuItem value="TIPO 1">TIPO 1</MenuItem>
                      <MenuItem value="TIPO 2">TIPO 2</MenuItem>
                      <MenuItem value="TIPO 3">TIPO 3</MenuItem>
                      <MenuItem value="TIPO 4">TIPO 4</MenuItem>
                      <MenuItem value="TIPO 5">TIPO 5</MenuItem>
                      <MenuItem value="TIPO 6">TIPO 6</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Cor dos Olhos</InputLabel>
                    <Select
                      value={searchCorOlhos}
                      onChange={(e) => setSearchCorOlhos(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      <MenuItem value="ÍRIS AZUL-CLARA">
                        ÍRIS AZUL-CLARA
                      </MenuItem>
                      <MenuItem value="ÍRIS AZUL-ESVERDEADA">
                        ÍRIS AZUL-ESVERDEADA
                      </MenuItem>
                      <MenuItem value="ÍRIS CINZA">ÍRIS CINZA</MenuItem>
                      <MenuItem value="ÍRIS AZUL-ESCURA">
                        ÍRIS AZUL-ESCURA
                      </MenuItem>
                      <MenuItem value="ÍRIS AZUL-CINZA COM MANCHAS AMARELAS/MARRONS">
                        ÍRIS AZUL-CINZA COM MANCHAS AMARELAS/MARRONS
                      </MenuItem>
                      <MenuItem value="ÍRIS VERDE">ÍRIS VERDE</MenuItem>
                      <MenuItem value="ÍRIS AVELÃ (CASTANHO-ESVERDEADA)">
                        ÍRIS AVELÃ (CASTANHO-ESVERDEADA)
                      </MenuItem>
                      <MenuItem value="ÍRIS ÂMBAR">ÍRIS ÂMBAR</MenuItem>
                      <MenuItem value="ÍRIS CASTANHO-CLARA">
                        ÍRIS CASTANHO-CLARA
                      </MenuItem>
                      <MenuItem value="ÍRIS CASTANHO-ESCURA">
                        ÍRIS CASTANHO-ESCURA
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Tipo Natural do Cabelo</InputLabel>
                    <Select
                      value={searchTipoCabelo}
                      onChange={(e) => setSearchTipoCabelo(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      <MenuItem value="1A LISOS (FINO)">1A LISOS (FINO)</MenuItem>
                      <MenuItem value="1B LISOS (MÉDIO)">
                        1B LISOS (MÉDIO)
                      </MenuItem>
                      <MenuItem value="1C LISOS (GROSSO)">
                        1C LISOS (GROSSO)
                      </MenuItem>
                      <MenuItem value="2A ONDULADOS (FINO)">
                        2A ONDULADOS (FINO)
                      </MenuItem>
                      <MenuItem value="2B ONDULADOS (MÉDIO)">
                        2B ONDULADOS (MÉDIO)
                      </MenuItem>
                      <MenuItem value="2C ONDULADOS (GROSSO)">
                        2C ONDULADOS (GROSSO)
                      </MenuItem>
                      <MenuItem value="3A CACHEADOS (SOLTO)">
                        3A CACHEADOS (SOLTO)
                      </MenuItem>
                      <MenuItem value="3B CACHEADOS (APERTADO)">
                        3B CACHEADOS (APERTADO)
                      </MenuItem>
                      <MenuItem value="4A CRESPOS (SOFT)">
                        4A CRESPOS (SOFT)
                      </MenuItem>
                      <MenuItem value="4B CRESPOS (WIRY)">
                        4B CRESPOS (WIRY)
                      </MenuItem>
                      <MenuItem value="4C CRESPOS (WIRY)">
                        4C CRESPOS (WIRY)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Raça/Etnia</InputLabel>
                    <Select
                      value={searchRaca}
                      onChange={(e) => setSearchRaca(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      <MenuItem value="BRANCO">BRANCO</MenuItem>
                      <MenuItem value="NEGRO">NEGRO</MenuItem>
                      <MenuItem value="PARDO">PARDO</MenuItem>
                      <MenuItem value="AMARELO">AMARELO</MenuItem>
                      <MenuItem value="INDÍGENA">INDÍGENA</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Cor Natural do Cabelo</InputLabel>
                    <Select
                      value={searchCorCabelo}
                      onChange={(e) => setSearchCorCabelo(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      <MenuItem value="GRISALHOS">GRISALHOS</MenuItem>
                      <MenuItem value="LOIROS">LOIROS</MenuItem>
                      <MenuItem value="RUIVOS">RUIVOS</MenuItem>
                      <MenuItem value="CASTANHOS">CASTANHOS</MenuItem>
                      <MenuItem value="PRETOS">PRETOS</MenuItem>
                      <MenuItem value="BRANCO">BRANCO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<FaSearch />}
                    fullWidth
                  >
                    Pesquisar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleNewSearch}
                    startIcon={<FaRedo />}
                    fullWidth
                  >
                    Nova Consulta
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>

          {searchCorOlhos ||
          searchTipoCabelo ||
          searchRaca ||
          searchCorCabelo ||
          searchCorPele ? (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h5" gutterBottom>
                Resultados da Pesquisa
              </Typography>
              <Divider sx={{ mb: 3 }} />

              <Grid container spacing={2}>
                {doadoras.length > 0 ? (
                  doadoras.map((doadora) => {
                    // Verifica a quantidade de óvulos congelados
                    const temOvulosCongelados =
                      doadora.quantidade_ovulos_congelados &&
                      Number(doadora.quantidade_ovulos_congelados) > 0
                        ? "Sim"
                        : "Não";

                    // Obtém a última doação de óvulos
                    const ultimaDoacao =
                      doadora.ovulos_doados && doadora.ovulos_doados.length > 0
                        ? doadora.ovulos_doados.reduce((ultima, atual) => {
                            return new Date(atual.transferDate) >
                              new Date(ultima.transferDate)
                              ? atual
                              : ultima;
                          })
                        : null;

                    return (
                      <Grid item xs={12} sm={6} md={4} key={doadora.id}>
                        <Card
                          sx={{
                            borderRadius: "16px",
                            boxShadow: 3,
                            position: "relative",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 140,
                              backgroundColor: "#f0f0f0",
                              cursor: "pointer",
                            }}
                            onClick={() => handleImageClick(doadora)}
                          >
                            <FaFemale size={50} color="#888" />
                          </Box>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Identificação Fechada
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Altura:</strong> {doadora.altura}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Cor da Pele:</strong> {doadora.cor_da_pele}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Cor dos Olhos:</strong>{" "}
                              {doadora.cor_dos_olhos}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Raça:</strong> {doadora.raca}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Tipo Natural do Cabelo:</strong>{" "}
                              {doadora.tipo_natural_do_cabelo}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Cor Natural do cabelo:</strong>{" "}
                              {doadora.cor_natural_do_cabelo}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Tipo Sanguíneo:</strong>{" "}
                              {doadora.tipo_sanguineo}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Doadora possui óvulos congelados:</strong>{" "}
                              {temOvulosCongelados}
                            </Typography>
                            {ultimaDoacao && (
                              <>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Última doação:</strong>{" "}
                                  {ultimaDoacao.transferDate}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Quantidade doada:</strong>{" "}
                                  {ultimaDoacao.quantity}
                                </Typography>
                              </>
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1" textAlign="center">
                      Nenhum resultado encontrado.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          ) : null}

          {isSearching && (
            <Modal open={isSearching}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "8px",
                }}
              >
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Buscando doadoras pelo perfil selecionado...
                </Typography>
              </Box>
            </Modal>
          )}

          {noResultsModal && (
            <Modal open={noResultsModal} onClose={handleCloseNoResultsModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Aviso
                </Typography>
                <Typography variant="body1">
                  Nenhum cadastro foi encontrado para os critérios fornecidos.
                </Typography>
                <Button
                  onClick={handleCloseNoResultsModal}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  OK
                </Button>
              </Box>
            </Modal>
          )}

          {selectedDoadora && (
            <Modal open={!!selectedDoadora} onClose={handleCloseModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: { xs: 300, md: 400 },
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {selectedDoadora.nome}
                </Typography>
                {selectedDoadora.photoURLs.map((photoURL, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={photoURL}
                    alt={selectedDoadora.nome}
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "300px",
                      objectFit: "contain",
                      borderRadius: "8px",
                      marginBottom: 2,
                    }}
                  />
                ))}
                <Button
                  onClick={handleCloseModal}
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                  startIcon={<FaTimes />}
                >
                  Fechar
                </Button>
              </Box>
            </Modal>
          )}

          {isBlockedModalOpen && (
            <Modal open={isBlockedModalOpen} onClose={handleCloseBlockedModal}>
              <Box sx={modalStyle}>
                <Typography variant="h6">Proposta em Andamento</Typography>
                <Button
                  onClick={handleCloseBlockedModal}
                  variant="contained"
                  color="primary"
                >
                  Fechar
                </Button>
              </Box>
            </Modal>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default BancoNacionalDeDoadoras;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};