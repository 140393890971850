import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export const generateMedicalReport = async (name, healthQuestions) => {
  const pdfUrl = '/relatoriomedico.pdf'; // Caminho no diretório public
  const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();

  const fontSize = 10;
  const textColor = rgb(0, 0, 0);
  const lineHeight = 16;
  const margin = 50;
  const dividerHeight = 4;
  const dividerColor = rgb(0.75, 0.75, 0.75);

  // Ajustar a posição inicial para evitar sobreposição com a logo
  let currentY = height - margin - 100;

  // Adicionar nome do cadastro no cabeçalho
  firstPage.drawText(`Nome: ${name}`, {
    x: margin,
    y: height - margin - 60,
    size: fontSize,
    font: boldFont,
    color: textColor,
  });

  const addNewPage = () => {
    const newPage = pdfDoc.addPage([width, height]);
    pages.push(newPage);
    currentY = height - margin;
    return newPage;
  };

  let currentPage = firstPage;

  healthQuestions.forEach((item, index) => {
    const questionText = `Pergunta ${index + 1}: ${item.question}`;
    const answerText = `Resposta: ${item.answer}`;

    if (currentY - 3 * lineHeight - dividerHeight < margin) {
      currentPage = addNewPage();
    }

    currentPage.drawText(questionText, {
      x: margin,
      y: currentY,
      size: fontSize,
      font,
      color: textColor,
    });
    currentY -= lineHeight;

    currentPage.drawText(answerText, {
      x: margin,
      y: currentY,
      size: fontSize,
      font: boldFont,
      color: textColor,
    });
    currentY -= lineHeight;

    currentPage.drawRectangle({
      x: margin,
      y: currentY - dividerHeight / 2,
      width: width - 2 * margin,
      height: dividerHeight,
      color: dividerColor,
    });
    currentY -= lineHeight;
  });

  const pdfBytesModified = await pdfDoc.save();
  const blob = new Blob([pdfBytesModified], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'relatorio.pdf';
  link.click();
};
