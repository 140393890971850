import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import jsPDF from "jspdf";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Sidebar from "./Sidebar";

import {
  Container,
  Typography,
  TextField,
  Modal,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  ListItemText,
  ListItem,
  InputLabel,
  List,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Divider,
  InputAdornment,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Backdrop,
  Fade,
} from "@mui/material";
import { FaUser, FaCircle, FaIdCard, FaSearch, FaLink, FaPrint } from "react-icons/fa";
import backgroundImage from "../assets/image.png";
import logo from "../assets/logoopaca.png";
import slogan from "../assets/slogan.png";
import escaladefitz from "../assets/escaladefitz.png";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ThemeProvider } from "@mui/material/styles";
import { generatePDF } from './generatePDF';
import theme from "../theme/theme"; // Importando o tema personalizado



const Pairings = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeTab, setActiveTab] = useState(null);
  const [tokensData, setTokensData] = useState({ tokens: 0, freePairings: 0 });
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [receptoraData, setReceptoraData] = useState(null);
  const [searchNome, setSearchNome] = useState("");
  const [searchCpf, setSearchCpf] = useState("");
  const [selectedDoadora, setSelectedDoadora] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchMessage, setSearchMessage] = useState("");
  const [searchProntuario, setSearchProntuario] = useState("");
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isParearModalOpen, setIsParearModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isProcessModalOpen, setIsProcessModalOpen] = useState(false);
  const [useNationalBank, setUseNationalBank] = useState(false);
  const [filterCorPele, setFilterCorPele] = useState("");
  const [filterCorOlhos, setFilterCorOlhos] = useState("");
  const [filterTipoCabelo, setFilterTipoCabelo] = useState("");
  const [filterCorCabelo, setFilterCorCabelo] = useState("");
  const [filterRaca, setFilterRaca] = useState("");
  const [loadingMessage, setLoadingMessage] = useState(
    "Aguarde, estamos analisando a semelhança entre a Receptora e Doadora..."
  );

  const db = getFirestore();
  const handleSearch = async (searchParams) => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.error("JWT Token não encontrado.");
      setSearchMessage("JWT Token não encontrado.");
      return;
    }

    try {
      const filteredSearchParams = Object.entries(searchParams).reduce(
        (acc, [key, value]) => {
          if (value) acc[key] = value;
          return acc;
        },
        {}
      );

      const response = await axios.post(
        "https://genematch.app/api/buscar-receptora",
        filteredSearchParams,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.length > 0) {
        setSearchResults(response.data);
        setSearchMessage("");
      } else {
        setSearchMessage(
          "Nenhuma receptora encontrada com os critérios de busca fornecidos."
        );
        setSearchResults([]);
      }
    } catch (error) {
      console.error("Erro ao buscar a receptora:", error);
      setSearchMessage("Erro ao buscar a receptora.");
      setSearchResults([]);
    }
  };

  const handleSelectReceptora = (receptora) => {
    setReceptoraData(receptora);
    setSearchResults([]);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchNome || searchCpf || searchProntuario) {
        handleSearch({
          nome: searchNome,
          cpf: searchCpf,
          n_prontuario: searchProntuario,
        });
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchNome, searchCpf, searchProntuario]);

  const iniciarPareamento = async (filtros) => {
    setIsLoading(true);
    setIsProcessModalOpen(true);
    const jwtToken = localStorage.getItem("jwtToken");
    if (!jwtToken) {
      console.error("JWT Token não encontrado.");
      setIsLoading(false);
      setIsProcessModalOpen(false);
      return;
    }

    console.log("Iniciando o processo de pareamento...");

    let filtrosSelecionados = {};
    for (const filtro in filtros) {
      if (filtros[filtro]) {
        filtrosSelecionados[filtro] = filtros[filtro];
      }
    }

    const payload = {
      name: receptoraData.name,
      filtros: filtrosSelecionados,
      useNationalBank: useNationalBank,
    };

    console.log("Enviando dados para o backend:", payload);

    const messages = [
      "Iniciando o processo de pareamento...",
      "Analisando dados fenotípicos...",
      "Verificando compatibilidade...",
      "Quase pronto, finalizando análise...",
    ];

    let messageIndex = 0;

    const intervalId = setInterval(() => {
      if (messageIndex < messages.length) {
        setLoadingMessage(messages[messageIndex]);
        messageIndex++;
      }
    }, 3000);

    try {
      const response = await axios.post(
        "https://genematch.app/api/buscar-doadoras",
        payload,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(
        "Dados atualizados para selectedDoadora:",
        response.data.resultados
      );
      setSelectedDoadora(response.data.resultados);

      if (receptoraData && receptoraData.name) {
        await salvarPareamentoNoBackend(
          { ...receptoraData, id: receptoraData.name },
          response.data.resultados
        );
        clearInterval(intervalId);
        setLoadingMessage("Pareamento concluído com sucesso!");
        setTimeout(() => {
          setIsLoading(false);
          setIsProcessModalOpen(false);
        }, 2000);
      } else {
        console.error("ID da receptora não encontrado");
        clearInterval(intervalId);
        setIsLoading(false);
        setIsProcessModalOpen(false);
      }
    } catch (error) {
      console.error("Erro ao realizar o pareamento:", error);
      clearInterval(intervalId);
      setIsLoading(false);
      setIsProcessModalOpen(false);

      if (error.response && error.response.status === 401) {
        alert("Autorização ausente ou inválida, refaça sua sessão");
      } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error.includes("API keys for empresaId")
      ) {
        alert(
          "Desculpe, este módulo não possui tokens suficientes para continuar. Por favor, entre em contato com o administrador em suporte@genematch.app."
        );
      } else {
        alert(
          "Ocorreu um erro ao processar esta requisição. Por favor, tente novamente mais tarde."
        );
      }
    }
  };

  const salvarPareamentoNoBackend = async (receptoraData, resultados) => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      const decodedToken = jwtDecode(jwtToken);
      const empresaId = decodedToken.empresaId;

      const payload = {
        receptoraData,
        resultados,
      };

      console.log("Enviando pareamento para o backend:", payload);

      const response = await axios.post(
        "https://genematch.app/api/salvar-pareamento",
        payload,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
            empresaId: empresaId,
          },
        }
      );

      console.log(
        "Resposta do backend ao salvar pareamento:",
        response.data
      );
      console.log(
        "Resultados do pareamento salvos com sucesso no backend."
      );
    } catch (error) {
      console.error(
        "Erro ao salvar resultados do pareamento no backend:",
        error
      );
    }
  };

  const handlePrint = async (receptoraData) => {
    const jwtToken = localStorage.getItem("jwtToken");
    const decodedToken = jwtDecode(jwtToken);
    const empresaId = decodedToken.empresaId;

    try {
      await generatePDF(receptoraData, selectedDoadora, empresaId);
    } catch (error) {
      console.error("An error occurred while printing: ", error);
    }
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  const handleAnalyzePairingClick = async () => {
    await fetchTokensData();
    setConfirmationModalOpen(true);
  };

  const fetchTokensData = async () => {
    const jwtToken = localStorage.getItem("jwtToken");
    let empresaId = null;

    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      empresaId = decodedToken.empresaId;

      if (!empresaId) {
        setErrorMessage("Erro interno: Empresa ID não encontrado.");
        return;
      }

      try {
        const response = await axios.get(
          `https://genematch.app/api/get-tokens?empresaId=${empresaId}`,
          { headers: { Authorization: `Bearer ${jwtToken}` } }
        );

        if (response.data.valid) {
          setTokensData(response.data.tokensData);
        } else {
          setTokensData({ tokens: 0, freePairings: 0 });
          setErrorMessage(
            response.data.message || "Sem tokens válidos disponíveis."
          );
        }
      } catch (error) {
        setErrorMessage("Erro ao buscar tokens. Por favor, tente novamente.");
      }
    }
  };

  const validatePairing = async () => {
    const jwtToken = localStorage.getItem("jwtToken");
    let empresaId = null;

    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      empresaId = decodedToken.empresaId;

      if (!empresaId) {
        setErrorMessage("Erro interno: Empresa ID não encontrado.");
        return false;
      }

      try {
        const response = await axios.post(
          "https://genematch.app/api/validate-pairing",
          {},
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              empresaId: empresaId,
            },
          }
        );

        if (response.data.valid) {
          return true;
        } else {
          setErrorMessage(
            response.data.message || "Sem tokens válidos disponíveis."
          );
          return false;
        }
      } catch (error) {
        setErrorMessage("Erro ao validar tokens. Por favor, tente novamente.");
        return false;
      }
    }
  };

  const handleConfirmPairing = async () => {
    setLoadingMessage("Aguarde, estamos verificando os tokens válidos...");
    setIsLoading(true);
    setIsProcessModalOpen(true);
    setConfirmationModalOpen(false);
    const isValid = await validatePairing();

    if (!isValid) {
      setIsLoading(false);
      setIsProcessModalOpen(false);
      return;
    }

    iniciarPareamento({
      filterCorPele,
      filterCorOlhos,
      filterCorCabelo,
      filterTipoCabelo,
      filterRaca,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" sx={{ mt: 2, mb: 4, ml: 2, width: "100%" }}>
        <Box display="flex">
          <Sidebar setActiveTab={() => {}} />
          <Box
            component="main"
            flexGrow={1}
            p={3}
            sx={{ width: "100%", ml: -5 }}
          >
            <Divider sx={{ mb: 3 }} />
            <Box sx={{ mb: 3 }}>
              <Card
                sx={{
                  p: 3,
                  mb: 2,
                  backgroundColor: "#f5f5f53d",
                  boxShadow: 3,
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "#840fb5" }}
                >
                  Pesquisa de Receptora
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Nome da Receptora"
                      fullWidth
                      value={searchNome}
                      onChange={(e) =>
                        setSearchNome(e.target.value.toUpperCase())
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaUser />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="CPF"
                      fullWidth
                      value={searchCpf}
                      onChange={(e) => setSearchCpf(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaIdCard />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Nº do Prontuário"
                      fullWidth
                      value={searchProntuario}
                      onChange={(e) => setSearchProntuario(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaIdCard />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                {searchMessage && (
                  <Typography variant="body2" color="error" mt={2}>
                    {searchMessage}
                  </Typography>
                )}
                {isLoading && (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                )}
                <List>
                  {searchResults.map((receptora) => (
                    <ListItem
                      key={receptora.cpf}
                      button
                      onClick={() => handleSelectReceptora(receptora)}
                    >
                      <ListItemText
                        primary={receptora.name}
                        secondary={`CPF: ${receptora.cpf}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Box>

            {receptoraData && (
              <>
                <Card
                  sx={{
                    p: 3,
                    mb: 2,
                    backgroundColor: "#ffffff",
                    boxShadow: 3,
                    borderRadius: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#840fb5",
                      color: "#fff",
                      padding: "16px",
                      borderRadius: "16px 16px 0 0",
                      position: "relative",
                    }}
                  >
                    {receptoraData.photoURLs && (
                      <CardMedia
                        component="img"
                        image={receptoraData.photoURLs[0]}
                        alt={receptoraData.name}
                        sx={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          border: "3px solid #fff",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                          position: "absolute",
                          top: "50%",
                          left: "16px",
                          transform: "translate(-50%, -50%)",
                        }}
                        onClick={() =>
                          handleImageClick(receptoraData.photoURLs[0])
                        }
                      />
                    )}
                    <Box sx={{ ml: "100px", width: "100%" }}>
                      <Typography variant="h6" sx={{ mb: 0 }}>
                        Receptora Encontrada
                      </Typography>
                    </Box>
                  </Box>
                  <CardContent sx={{ mt: "20px", pt: "20px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <strong>Nome:</strong> {receptoraData.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>CPF:</strong> {receptoraData.cpf}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Cor Natural do Cabelo:</strong>{" "}
                          {receptoraData.cor_natural_do_cabelo}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Cor da Pele:</strong>{" "}
                          {receptoraData.cor_da_pele}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Raça/Etnia:</strong> {receptoraData.raca}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Tipo Natural do Cabelo:</strong>{" "}
                          {receptoraData.tipo_natural_do_cabelo}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Cor dos Olhos:</strong>{" "}
                          {receptoraData.cor_dos_olhos}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Altura:</strong> {receptoraData.altura}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Idade:</strong> {receptoraData.idade}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Tipo Sanguíneo:</strong>{" "}
                          {receptoraData.tipo_sanguineo}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Nº do Prontuário:</strong>{" "}
                          {receptoraData.n_do_prontuario}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>País:</strong> {receptoraData.pais}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                  </CardContent>
                </Card>

                <Card
                  sx={{ p: 3, mb: 2, backgroundColor: "#ffffff", boxShadow: 3 }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "#840fb5" }}
                  >
                    Filtros de Pareamento
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Cor da Pele</InputLabel>
                        <Select
                          value={filterCorPele}
                          onChange={(e) =>
                            setFilterCorPele(
                              e.target.value.replace(/\s+/g, " ").trim()
                            )
                          }
                        >
                          <MenuItem value="">Cor da Pele</MenuItem>
                          <MenuItem value="TIPO 1">TIPO 1</MenuItem>
                          <MenuItem value="TIPO 2">TIPO 2</MenuItem>
                          <MenuItem value="TIPO 3">TIPO 3</MenuItem>
                          <MenuItem value="TIPO 4">TIPO 4</MenuItem>
                          <MenuItem value="TIPO 5">TIPO 5</MenuItem>
                          <MenuItem value="TIPO 6">TIPO 6</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Cor dos Olhos</InputLabel>
                        <Select
                          value={filterCorOlhos}
                          onChange={(e) =>
                            setFilterCorOlhos(
                              e.target.value.replace(/\s+/g, " ").trim()
                            )
                          }
                        >
                          <MenuItem value="">Cor dos Olhos</MenuItem>
                          <MenuItem value="ÍRIS AZUL-CLARA">
                            ÍRIS AZUL-CLARA
                          </MenuItem>
                          <MenuItem value="ÍRIS AZUL-ESVERDEADA">
                            ÍRIS AZUL-ESVERDEADA
                          </MenuItem>
                          <MenuItem value="ÍRIS CINZA">ÍRIS CINZA</MenuItem>
                          <MenuItem value="ÍRIS AZUL-ESCURA">
                            ÍRIS AZUL-ESCURA
                          </MenuItem>
                          <MenuItem value="ÍRIS AZUL-CINZA COM MANCHAS AMARELAS/MARRONS">
                            ÍRIS AZUL-CINZA COM MANCHAS AMARELAS/MARRONS
                          </MenuItem>
                          <MenuItem value="ÍRIS VERDE">ÍRIS VERDE</MenuItem>
                          <MenuItem value="ÍRIS AVELÃ (CASTANHO-ESVERDEADA)">
                            ÍRIS AVELÃ (CASTANHO-ESVERDEADA)
                          </MenuItem>
                          <MenuItem value="ÍRIS ÂMBAR">ÍRIS ÂMBAR</MenuItem>
                          <MenuItem value="ÍRIS CASTANHO-CLARA">
                            ÍRIS CASTANHO-CLARA
                          </MenuItem>
                          <MenuItem value="ÍRIS CASTANHO-ESCURA">
                            ÍRIS CASTANHO-ESCURA
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Tipo Natural do Cabelo</InputLabel>
                        <Select
                          value={filterTipoCabelo}
                          onChange={(e) =>
                            setFilterTipoCabelo(
                              e.target.value.replace(/\s+/g, " ").trim()
                            )
                          }
                        >
                          <MenuItem value="">Tipo Natural do Cabelo</MenuItem>
                          <MenuItem value="1A LISOS (FINO)">
                            1A LISOS (FINO)
                          </MenuItem>
                          <MenuItem value="1B LISOS (MÉDIO)">
                            1B LISOS (MÉDIO)
                          </MenuItem>
                          <MenuItem value="1C LISOS (GROSSO)">
                            1C LISOS (GROSSO)
                          </MenuItem>
                          <MenuItem value="2A ONDULADOS (FINO)">
                            2A ONDULADOS (FINO)
                          </MenuItem>
                          <MenuItem value="2B ONDULADOS (MÉDIO)">
                            2B ONDULADOS (MÉDIO)
                          </MenuItem>
                          <MenuItem value="2C ONDULADOS (GROSSO)">
                            2C ONDULADOS (GROSSO)
                          </MenuItem>
                          <MenuItem value="3A CACHEADOS (SOLTO)">
                            3A CACHEADOS (SOLTO)
                          </MenuItem>
                          <MenuItem value="3B CACHEADOS (APERTADO)">
                            3B CACHEADOS (APERTADO)
                          </MenuItem>
                          <MenuItem value="4A CRESPOS (SOFT)">
                            4A CRESPOS (SOFT)
                          </MenuItem>
                          <MenuItem value="4B CRESPOS (WIRY)">
                            4B CRESPOS (WIRY)
                          </MenuItem>
                          <MenuItem value="4C CRESPOS (WIRY)">
                            4C CRESPOS (WIRY)
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Cor Natural do Cabelo</InputLabel>
                        <Select
                          value={filterCorCabelo}
                          onChange={(e) =>
                            setFilterCorCabelo(
                              e.target.value.replace(/\s+/g, " ").trim()
                            )
                          }
                        >
                          <MenuItem value="">Cor Natural do Cabelo</MenuItem>
                          <MenuItem value="GRISALHOS">Grisalhos</MenuItem>
                          <MenuItem value="LOIROS">Loiros</MenuItem>
                          <MenuItem value="RUIVOS">Ruivos</MenuItem>
                          <MenuItem value="CASTANHOS">Castanhos</MenuItem>
                          <MenuItem value="PRETOS">Pretos</MenuItem>
                          <MenuItem value="BRANCO">Branco</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Raça/Etnia</InputLabel>
                        <Select
                          value={filterRaca}
                          onChange={(e) =>
                            setFilterRaca(
                              e.target.value.replace(/\s+/g, " ").trim()
                            )
                          }
                        >
                          <MenuItem value="">Raça/Etnia</MenuItem>
                          <MenuItem value="BRANCO">BRANCO</MenuItem>
                          <MenuItem value="NEGRO">NEGRO</MenuItem>
                          <MenuItem value="PARDO">PARDO</MenuItem>
                          <MenuItem value="AMARELO">AMARELO</MenuItem>
                          <MenuItem value="INDÍGENA">INDÍGENA</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Checkbox to select whether to use the National Donors Bank */}
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={useNationalBank}
                            onChange={(e) =>
                              setUseNationalBank(e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Usar Banco Nacional de Doadoras"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    startIcon={<FaLink />}
                    sx={{ mt: 2, position: "relative" }}
                    onClick={handleAnalyzePairingClick}
                  >
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                    Parear Receptora
                  </Button>
                </Card>
              </>
            )}

            {selectedDoadora && (
              <Card
                sx={{ p: 3, mb: 2, backgroundColor: "#fff", boxShadow: 3 }}
              >
                <Typography variant="h6" gutterBottom>
                  Resultado do Pareamento Fenotípico
                </Typography>
                <Grid container spacing={2}>
                  {selectedDoadora.map((resultado, index) => {
                    const isActive =
                      resultado.detalhes.quantidade_ovulos_congelados &&
                      resultado.detalhes.quantidade_ovulos_congelados > 0;
                    return (
                      <Grid item xs={12} md={4} key={index}>
                        <Card
                          sx={{
                            borderRadius: "16px",
                            boxShadow: 3,
                            position: "relative",
                            overflow: "visible",
                            backgroundColor: isActive ? "#fff" : "#f5f5f5",
                          }}
                        >
                          {!isActive && (
                            <Typography
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 10,
                                color: "red",
                                fontSize: "14px",
                                fontWeight: "bold",
                                zIndex: 2,
                              }}
                            >
                              0 óvulos congelados
                            </Typography>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#840fb5",
                              color: "#fff",
                              padding: "16px",
                              borderRadius: "16px 16px 0 0",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50px",
                                transform: "translate(-50%, -50%)",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CardMedia
                                component="img"
                                image={resultado.detalhes.photoURLs[0]}
                                alt={`Doadora ${resultado.doadora_nome}`}
                                sx={{
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "50%",
                                  border: "3px solid #fff",
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                }}
                                onClick={() =>
                                  handleImageClick(
                                    resultado.detalhes.photoURLs[0]
                                  )
                                }
                              />
                            </Box>
                            <Box sx={{ ml: "100px", width: "100%" }}>
                              <Typography variant="h6" sx={{ mb: 0 }}>
                                {resultado.tipoCadastro}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: "#ddd", textAlign: "right" }}
                              >
                                Similaridade Contorno Facial:{" "}
                                {resultado.similaridade_contorno_face.toFixed(
                                  2
                                )}
                                %
                              </Typography>
                            </Box>
                          </Box>
                          <CardContent
                            sx={{
                              mt: "20px",
                              pt: "20px",
                              filter: isActive ? "none" : "grayscale(100%)",
                            }}
                          >
                            <Typography variant="h6" gutterBottom>
                              {resultado.doadora_nome}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Identidade Aberta
                            </Typography>
                            <Divider sx={{ my: 1 }} />
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Nome:</strong>{" "}
                                  {resultado.doadora_nome}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Altura:</strong>{" "}
                                  {resultado.detalhes.altura}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Cor da Pele:</strong>{" "}
                                  {resultado.detalhes.cor_da_pele}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Cor dos Olhos:</strong>{" "}
                                  {resultado.detalhes.cor_dos_olhos}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Raça:</strong>{" "}
                                  {resultado.detalhes.raca}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Tipo Natural do Cabelo:</strong>{" "}
                                  {resultado.detalhes.tipo_natural_do_cabelo}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Cor Natural do cabelo:</strong>{" "}
                                  {resultado.detalhes.cor_natural_do_cabelo}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Tipo Sanguíneo:</strong>{" "}
                                  {resultado.detalhes.tipo_sanguineo}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <strong>Empresa:</strong>{" "}
                                  {resultado.detalhes.Empresa}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<FaPrint />}
                  sx={{ mt: 2 }}
                  onClick={async () => {
                    const empresaId = localStorage.getItem("empresaId");
                    await handlePrint(
                      receptoraData,
                      selectedDoadora,
                      empresaId
                    );
                  }}
                >
                  Imprimir resultado
                </Button>
              </Card>
            )}

            <Modal
              open={isSearchModalOpen}
              onClose={() => setIsSearchModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 500 }}
            >
              <Fade in={isSearchModalOpen}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Resultados da Pesquisa de Receptora
                  </Typography>
                  <Typography>{searchMessage}</Typography>
                  <Button
                    onClick={() => setIsSearchModalOpen(false)}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Fechar
                  </Button>
                </Box>
              </Fade>
            </Modal>

            <Modal
              open={isParearModalOpen}
              onClose={() => setIsParearModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 500 }}
            >
              <Fade in={isParearModalOpen}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Pareamento realizado com sucesso
                  </Typography>
                  <Typography>{searchMessage}</Typography>
                  <Button
                    onClick={() => setIsParearModalOpen(false)}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Fechar
                  </Button>
                </Box>
              </Fade>
            </Modal>

            <Modal
              open={confirmationModalOpen}
              onClose={() => setConfirmationModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 500 }}
            >
              <Fade in={confirmationModalOpen}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2, color: "black" }}>
                    Você tem {tokensData.tokens} tokens e{" "}
                    {tokensData.freePairings} pareamentos gratuitos
                    disponíveis.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmPairing}
                    sx={{ mt: 2 }}
                  >
                    Confirmar Análise
                  </Button>
                </Box>
              </Fade>
            </Modal>

            <Modal
              open={isProcessModalOpen}
              onClose={() => setIsProcessModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 500 }}
            >
              <Fade in={isProcessModalOpen}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                >
                  {isLoading && (
                    <>
                      <CircularProgress />
                      <Typography
                        variant="h6"
                        sx={{ mt: 2, color: "black" }}
                      >
                        {loadingMessage}
                      </Typography>
                    </>
                  )}
                </Box>
              </Fade>
            </Modal>

            <Modal
              open={isInfoModalOpen}
              onClose={() => setIsInfoModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 500 }}
            >
              <Fade in={isInfoModalOpen}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Informação
                  </Typography>
                  <Typography>
                    Você está prestes a pesquisar dentro do Banco Nacional de
                    Doadoras. Esta consulta pode levar algum tempo,
                    especialmente se nenhum filtro específico for selecionado.
                    Recomendamos aplicar filtros para otimizar o tempo de busca.
                  </Typography>
                  <Button
                    onClick={() => setIsInfoModalOpen(false)}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Fechar
                  </Button>
                </Box>
              </Fade>
            </Modal>

            {selectedImage && (
              <Box className="modal" onClick={() => setSelectedImage(null)}>
                <Box className="modal-content">
                  <img src={selectedImage} alt="Imagem em tamanho maior" />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default Pairings;