import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const generatePDF = async (receptoraData, doadoraData, empresaId) => {
  const pdfUrl = '/PareamentoEncontrado.pdf'; // Caminho no diretório public
  const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  const { width, height } = firstPage.getSize();

  // Definir fonte e cor
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const fontSize = 10;
  const textColor = rgb(0, 0, 0);

  // Adicionar logo da empresa
  if (empresaId) {
    const storage = getStorage();
    const logoRef = ref(storage, `${empresaId}/logo/logo.png`);
    try {
      const logoUrl = await getDownloadURL(logoRef);
      const logoBytes = await fetch(logoUrl, { mode: 'cors' }).then(res => res.arrayBuffer());
      const logoImage = await pdfDoc.embedPng(logoBytes);
      firstPage.drawImage(logoImage, {
        x: width - 150, // Ajuste conforme necessário
        y: height - 100,
        width: 100,
        height: 50,
      });
    } catch (error) {
      console.error("Erro ao baixar o logo da empresa:", error);
    }
  }

  // Adicionar dados da receptora na área maior
  const col1X = 50;
  const col2X = width / 2 + 20;
  let currentY = height - 185; // Ajuste a posição inicial conforme necessário

  const drawText = (label, value, x, y) => {
    if (value) {
      firstPage.drawText(label, { x, y, size: fontSize, font: boldFont, color: textColor });
      firstPage.drawText(value, { x, y: y - 10, size: fontSize, font, color: textColor });
    }
  };

  drawText('Nome:', receptoraData.name, col1X, currentY);
  drawText('Cor da Pele:', receptoraData.cor_da_pele, col2X, currentY);

  currentY -= 30;
  drawText('Prontuario interno:', receptoraData.n_do_prontuario, col1X, currentY);
  drawText('Cor dos Olhos:', receptoraData.cor_dos_olhos, col2X, currentY);

  currentY -= 30;
  drawText('Tipo Sanguineo:', receptoraData.tipo_sanguineo, col1X, currentY);
  drawText('Cor natural do cabelo:', receptoraData.cor_natural_do_cabelo, col2X, currentY);

  currentY -= 30;
  drawText('Idade:', receptoraData.idade, col1X, currentY);
  drawText('Raça / Etinia:', receptoraData.raca, col2X, currentY);

  currentY -= 30;
  drawText('Altura:', receptoraData.altura, col1X, currentY);
  drawText('Peso:', receptoraData.pesokg, col2X, currentY);

  // Adicionar dados das doadoras nos cartões
  const doadoraCardStartX = 50;
  const doadoraCardStartY = currentY - 90; // Ajuste a posição inicial conforme necessário
  const cardWidth = 170;
  let currentCardX = doadoraCardStartX;

  doadoraData.forEach((doadora, index) => {
    if (index >= 3) return; // Limitar a 3 doadoras

    let currentYCard = doadoraCardStartY;

    const drawDoadoraText = (label, value, x, y) => {
      if (value) {
        firstPage.drawText(label, { x, y, size: fontSize, font: boldFont, color: textColor });
        firstPage.drawText(value, { x, y: y - 10, size: fontSize, font, color: textColor });
      }
    };

    drawDoadoraText(`Opção ${index + 1}`, '', currentCardX, currentYCard);
    currentYCard -= 10;
    drawDoadoraText('Percentagem de semelhança:', `${doadora.similaridade_contorno_face.toFixed(2)}%`, currentCardX, currentYCard);

    currentYCard -= 20;
    drawDoadoraText('Cor da Pele:', doadora.detalhes.cor_da_pele, currentCardX, currentYCard);

    currentYCard -= 20;
    drawDoadoraText('Cor dos Olhos:', doadora.detalhes.cor_dos_olhos, currentCardX, currentYCard);

    currentYCard -= 20;
    drawDoadoraText('Cor natural do cabelo:', doadora.detalhes.cor_natural_do_cabelo, currentCardX, currentYCard);

    currentYCard -= 20;
    drawDoadoraText('Raça / Etinia:', doadora.detalhes.raca, currentCardX, currentYCard);

    currentYCard -= 20;
    drawDoadoraText('Tipo Sanguineo:', doadora.tipo_sanguineo, currentCardX, currentYCard);

    currentYCard -= 20;
    drawDoadoraText('Idade:', doadora.detalhes.idade, currentCardX, currentYCard);

    currentYCard -= 20;
    drawDoadoraText('Altura:', doadora.detalhes.altura, currentCardX, currentYCard);

    currentYCard -= 20;
    drawDoadoraText('Peso:', doadora.detalhes.pesokg, currentCardX, currentYCard);

    currentYCard -= 20;

    drawDoadoraText('Empresa:', doadora.detalhes.Empresa, currentCardX, currentYCard);

    currentCardX += cardWidth + 10; // Move para a próxima coluna de cartão
  });

  // Salvar o PDF modificado
  const pdfBytesModified = await pdfDoc.save();

  // Criar um link para download do arquivo PDF modificado
  const blob = new Blob([pdfBytesModified], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = receptoraData ? `${receptoraData.name}_GENEMATCH.pdf` : 'genematch.pdf';
  link.click();
};
