import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import axios from "axios";
import { jwtDecode } from "jwt-decode";


const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const QuestionsModal = ({ open, onClose, onBack }) => {
  const [newQuestion, setNewQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [questionType, setQuestionType] = useState("text");
  const [options, setOptions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [newOption, setNewOption] = useState("");
  const [empresaId, setEmpresaId] = useState("");

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      setEmpresaId(decodedToken.empresaId);

      if (!decodedToken.empresaId) {
        console.error("empresaId está vazio ou indefinido.");
      } else {
        fetchQuestions(decodedToken.empresaId);
      }
    }
  }, [open]);

  const fetchQuestions = async (empresaId) => {
    try {
      const response = await axios.get(
        `https://genematch.app/api/questions?empresaId=${empresaId}`
      );
      console.log("Fetched questions:", response.data);
      setQuestions(response.data.questions || []);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim() === "") {
      alert("A pergunta não pode estar vazia");
      return;
    }

    const newQuestions = [...questions];
    const question = { text: newQuestion, type: questionType, options };
    if (currentQuestionIndex !== null) {
      newQuestions[currentQuestionIndex] = question;
      setCurrentQuestionIndex(null);
    } else {
      newQuestions.push(question);
    }
    setQuestions(newQuestions);
    setNewQuestion("");
    setQuestionType("text");
    setOptions([]);
    setNewOption("");
  };

  const handleAddOption = () => {
    if (newOption.trim() !== "") {
      setOptions([...options, newOption]);
      setNewOption("");
    }
  };

  const handleSaveQuestions = async () => {
    try {
      if (!empresaId) {
        console.error("empresaId is not set");
        return;
      }

      const response = await axios.post("https://genematch.app/api/questions", {
        empresaId,
        questions,
      });
      console.log(response.data);
      alert("Perguntas salvas com sucesso!");
      onClose();
    } catch (error) {
      console.error("Erro ao salvar perguntas: ", error);
      alert("Erro ao salvar perguntas, tente novamente.");
    }
  };

  const handleDeleteQuestion = async (index) => {
    try {
      if (!empresaId) {
        console.error("empresaId is not set");
        return;
      }

      console.log("Deleting question at index:", index);
      await axios.delete("https://genematch.app/api/questions", {
        data: { empresaId, index },
      });
      setQuestions(questions.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleEditQuestion = (index) => {
    const question = questions[index];
    setNewQuestion(question.text);
    setQuestionType(question.type);
    setOptions(question.options || []);
    setCurrentQuestionIndex(index);
  };

  const handleDeleteOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <IconButton
            onClick={onBack}
            aria-label="back"
            sx={{ position: "absolute", left: 8, top: 8 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h4"
            component="h2"
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            Criar Lista de Perguntas
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" sx={{ mb: 2 }}>
          <InfoIcon fontSize="small" sx={{ verticalAlign: "middle", mr: 1 }} />
          Para criar uma nova pergunta, preencha o campo abaixo e selecione o
          tipo de pergunta.
        </Typography>

        <Grid item xs={12} md={4}>
          <Select
            label="Tipo de Pergunta"
            value={questionType}
            onChange={(e) => setQuestionType(e.target.value)}
            fullWidth
            margin="normal"
            displayEmpty
          >
            <MenuItem value="text">Texto</MenuItem>
            <MenuItem value="multipleChoice">Múltipla Escolha</MenuItem>
          </Select>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                label="Nova Pergunta"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Grid>
        {questionType === "multipleChoice" && (
          <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6">Opções</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <TextField
                  label="Nova Opção"
                  value={newOption}
                  onChange={(e) => setNewOption(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleAddOption}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <List>
              {options.map((option, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteOption(index)}
                      size="small"
                      sx={{ color: "red" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={option} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        <Button
          onClick={handleAddQuestion}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={currentQuestionIndex !== null ? <EditIcon /> : <AddIcon />}
          sx={{ mt: 2 }}
        >
          {currentQuestionIndex !== null
            ? "Editar Pergunta"
            : "Adicionar Pergunta"}
        </Button>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" sx={{ mb: 1 }}>
          Lista de Perguntas
        </Typography>
        <List>
          {questions.map((question, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <Box>
                  <Tooltip title="Editar" arrow>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleEditQuestion(index)}
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Excluir" arrow>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteQuestion(index)}
                      size="small"
                      sx={{ color: "red" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            >
              <ListItemText
                primary={question.text}
                secondary={
                  question.type === "multipleChoice"
                    ? "Múltipla Escolha"
                    : "Texto"
                }
              />
            </ListItem>
          ))}
        </List>
        <Button
          onClick={handleSaveQuestions}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Salvar Perguntas
        </Button>
      </Box>
    </Modal>
  );
};

export default QuestionsModal;
