import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import DonorRegistration from './components/DonorRegistration';
import RecipientRegistration from './components/RecipientRegistration';
import Pairings from './components/Pairings';
import BancoNacionalDeDoadoras from './components/BancoNacionalDeDoadoras';
import BancoDeDoadoras from './components/BancoDeDoadoras';
import ImageUploader from "./components/ImageUploader";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { IconContext } from 'react-icons';
import { styled } from "@mui/system";
import { FaUser, FaUserAlt, FaUserFriends, FaBuilding, FaMapMarkerAlt } from "react-icons/fa";
import { Box, CssBaseline, Grid, Card, Typography, Checkbox, FormControlLabel, Divider, Paper, GlobalStyles, useTheme } from "@mui/material";
import { getFirestore, collection, getDocs, query, limit, startAfter } from "firebase/firestore";
import { jwtDecode } from "jwt-decode";


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DashboardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
}));

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  overflow: "auto",
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const MainCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const MapSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

const Legend = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  zIndex: 1000,
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
}));

const PopupContent = styled(Box)(({ theme }) => ({
  maxWidth: "150px",
  wordWrap: "break-word",
  [theme.breakpoints.down('sm')]: {
    maxWidth: "100%",
  },
}));

const FilterBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  zIndex: 1000,
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
  },
}));

const DashboardHome = ({
  dashboardData,
  empresaId,
  donorsLocations,
  nationalDonorsLocations,
  filter,
  setFilter,
}) => {
  const mapRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (mapRef.current && donorsLocations.length > 0) {
      const validLocations = (filter === 'donors' ? donorsLocations : nationalDonorsLocations).filter(
        (location) => location.latitude && location.longitude,
      );
      if (validLocations.length > 0) {
        const bounds = L.latLngBounds(
          validLocations.map((location) => [location.latitude, location.longitude]),
        );
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [donorsLocations, nationalDonorsLocations, filter]);

  return (
    <MainCard elevation={3}>
      <Typography variant="h4" gutterBottom>
        Seja bem-vindo, {empresaId}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            elevation={3}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                p: 1,
              },
            }}
          >
            <FaUser size={30} color="#1976d2" />
            <Typography variant="h6">Doadoras cadastradas</Typography>
            <Typography variant="h4">{dashboardData?.numDonors}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            elevation={3}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                p: 1,
              },
            }}
          >
            <FaUserAlt size={30} color="#d32f2f" />
            <Typography variant="h6">Receptoras cadastradas</Typography>
            <Typography variant="h4">{dashboardData?.numRecipients}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            elevation={3}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                p: 1,
              },
            }}
          >
            <FaUserFriends size={30} color="#388e3c" />
            <Typography variant="h6">Banco Nacional de doadoras</Typography>
            <Typography variant="h4">
              {dashboardData?.numNationalDonors}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card
            elevation={3}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                p: 1,
              },
            }}
          >
            <FaBuilding size={30} color="#fbc02d" />
            <Typography variant="h6">Clínicas Cadastradas</Typography>
            <Typography variant="h4">{dashboardData?.numEmpresas}</Typography>
          </Card>
        </Grid>
      </Grid>
      <Divider sx={{ my: 4 }} />
      <MapSection>
        <MapContainer
          center={[-22.9021887, -43.6816902]}
          zoom={12}
          style={{ height: "500px", width: "100%", borderRadius: '8px' }}
          whenCreated={(map) => (mapRef.current = map)}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {(filter === 'donors' ? donorsLocations : nationalDonorsLocations).map((location, index) => {
            if (location.latitude && location.longitude) {
              return (
                <Marker key={index} position={[location.latitude, location.longitude]}>
                  <Popup>
                    <PopupContent style={{ padding: '4px', maxWidth: '150px', [theme.breakpoints.down('sm')]: { maxWidth: '100%' } }}>
                      <Typography variant="body1" noWrap style={{ marginBottom: '0.3em' }}>
                        {location.name}
                      </Typography>
                      <Typography variant="body2" noWrap style={{ marginBottom: '0.3em' }}>
                        Óvulos congelados:
                      </Typography>
                      <Typography variant="body2" noWrap style={{ marginBottom: '0.3em' }}>
                        {location.quantidade_ovulos_congelados}
                      </Typography>
                      <Typography variant="body2" noWrap style={{ marginBottom: '0.3em' }}>
                        Data do congelamento:
                      </Typography>
                      <Typography variant="body2" noWrap style={{ marginBottom: '0.3em' }}>
                        {location.data_do_congelamento}
                      </Typography>
                      {location.signedPhotoURLs && location.signedPhotoURLs.length > 0 && (
                        <img
                          src={location.signedPhotoURLs[0]}
                          alt={location.name}
                          style={{
                            width: "80px",
                            height: "auto",
                            objectFit: "cover",
                            marginTop: "0.3em",
                          }}
                        />
                      )}
                    </PopupContent>
                  </Popup>
                </Marker>
              );
            }
            return null;
          })}
          <Legend elevation={3}>
            <Typography variant="h6" gutterBottom>Legenda</Typography>
            <Typography variant="body2">
              <FaMapMarkerAlt color="blue" /> Doadoras
            </Typography>
            <Typography variant="body2">
              <FaMapMarkerAlt color="red" /> Clínicas do Banco Nacional de Doadoras
            </Typography>
          </Legend>
          <FilterBox elevation={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter === 'national'}
                  onChange={() => setFilter(filter === 'donors' ? 'national' : 'donors')}
                  color="primary"
                />
              }
              label={filter === 'donors' ? 'Ver Banco Nacional de Doadoras' : 'Ver Doadoras'}
              labelPlacement="end"
            />
          </FilterBox>
        </MapContainer>
      </MapSection>
    </MainCard>
  );
};

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [empresaId, setEmpresaId] = useState("");
  const [donorsLocations, setDonorsLocations] = useState([]);
  const [nationalDonorsLocations, setNationalDonorsLocations] = useState([]);
  const [filter, setFilter] = useState('donors');

  const fetchDashboardData = async (empresaId) => {
    try {
      const db = getFirestore();

      if (!empresaId) {
        console.warn("EmpresaId não fornecido. Não é possível buscar dados.");
        return;
      }

      const batchSize = 100;

      let lastDonorDoc = null;
      let donors = [];
      do {
        const donorsQuery = lastDonorDoc
          ? query(
              collection(db, `Empresas/${empresaId}/Doadoras`),
              startAfter(lastDonorDoc),
              limit(batchSize)
            )
          : query(collection(db, `Empresas/${empresaId}/Doadoras`), limit(batchSize));

        const donorsSnapshot = await getDocs(donorsQuery);
        donors.push(...donorsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          latitude: parseFloat(doc.data().latitude),
          longitude: parseFloat(doc.data().longitude),
        })));

        lastDonorDoc = donorsSnapshot.docs[donorsSnapshot.docs.length - 1];
      } while (lastDonorDoc);

      const numDonors = donors.length;

      let lastRecipientDoc = null;
      let recipients = [];
      do {
        const recipientsQuery = lastRecipientDoc
          ? query(
              collection(db, `Empresas/${empresaId}/Receptoras`),
              startAfter(lastRecipientDoc),
              limit(batchSize)
            )
          : query(collection(db, `Empresas/${empresaId}/Receptoras`), limit(batchSize));

        const recipientsSnapshot = await getDocs(recipientsQuery);
        recipients.push(...recipientsSnapshot.docs.map((doc) => doc.data()));

        lastRecipientDoc = recipientsSnapshot.docs[recipientsSnapshot.docs.length - 1];
      } while (lastRecipientDoc);

      const numRecipients = recipients.length;

      let lastNationalDonorDoc = null;
      let nationalDonors = [];
      do {
        const nationalDonorsQuery = lastNationalDonorDoc
          ? query(
              collection(db, "BancoNacionalDeDoadoras"),
              startAfter(lastNationalDonorDoc),
              limit(batchSize)
            )
          : query(collection(db, "BancoNacionalDeDoadoras"), limit(batchSize));

        const nationalDonorsSnapshot = await getDocs(nationalDonorsQuery);
        nationalDonors.push(...nationalDonorsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          latitude: parseFloat(doc.data().latitude),
          longitude: parseFloat(doc.data().longitude),
          quantidade_ovulos_congelados: doc.data().quantidade_ovulos_congelados,
        })));

        lastNationalDonorDoc = nationalDonorsSnapshot.docs[nationalDonorsSnapshot.docs.length - 1];
      } while (lastNationalDonorDoc);

      const numNationalDonors = nationalDonors.length;

      let lastCompanyDoc = null;
      let companies = [];
      do {
        const empresasQuery = lastCompanyDoc
          ? query(
              collection(db, "Empresas"),
              startAfter(lastCompanyDoc),
              limit(batchSize)
            )
          : query(collection(db, "Empresas"), limit(batchSize));

        const empresasSnapshot = await getDocs(empresasQuery);
        companies.push(...empresasSnapshot.docs.map((doc) => doc.data()));

        lastCompanyDoc = empresasSnapshot.docs[empresasSnapshot.docs.length - 1];
      } while (lastCompanyDoc);

      const numEmpresas = companies.length;

      return {
        numDonors,
        numRecipients,
        numNationalDonors,
        numEmpresas,
        donorsLocations: donors,
        nationalDonorsLocations: nationalDonors,
      };
    } catch (error) {
      console.error("Erro ao buscar dados do dashboard:", error);
    }
  };

  const fetchDonors = async (empresaId) => {
    const jwtToken = localStorage.getItem("jwtToken");

    if (!jwtToken) {
      console.warn("Token JWT não encontrado. Não é possível buscar dados.");
      return;
    }

    try {
      console.log("Buscando dados das doadoras...");
      const response = await axios.get("https://genematch.app/api/donors", {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        params: {
          empresaId,
        },
      });

      if (response.data) {
        console.log("Dados das doadoras recebidos:", response.data);
        setDonorsLocations(response.data);
        localStorage.setItem("donorsLocations", JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Erro ao buscar dados das doadoras:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const jwtToken = localStorage.getItem("jwtToken");

      if (!jwtToken) {
        console.warn("Token JWT não encontrado. Não é possível buscar dados.");
        return;
      }

      const decodedToken = jwtDecode(jwtToken);
      const decodedEmpresaId = decodedToken.empresaId;
      setEmpresaId(decodedEmpresaId);

      const cachedDashboardData = localStorage.getItem("dashboardData");
      const cachedDonorsLocations = localStorage.getItem("donorsLocations");

      if (cachedDashboardData) {
        const parsedDashboardData = JSON.parse(cachedDashboardData);
        setDashboardData(parsedDashboardData);
        setDonorsLocations(parsedDashboardData.donorsLocations || []);
        setNationalDonorsLocations(parsedDashboardData.nationalDonorsLocations || []);
      } else {
        try {
          const data = await fetchDashboardData(decodedEmpresaId);
          setDashboardData(data);
          localStorage.setItem("dashboardData", JSON.stringify(data));
          setDonorsLocations(data.donorsLocations);
          setNationalDonorsLocations(data.nationalDonorsLocations);
        } catch (error) {
          console.error("Erro ao buscar dados do dashboard:", error);
        }
      }

      if (cachedDonorsLocations) {
        const parsedDonorsLocations = JSON.parse(cachedDonorsLocations);
        setDonorsLocations(parsedDonorsLocations);
      } else {
        fetchDonors(decodedEmpresaId);
      }
    };

    const timeoutId = setTimeout(() => {
      fetchData();
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <DashboardContainer>
      <CssBaseline />
      <GlobalStyles
        styles={{
          '.leaflet-popup-content p': {
            margin: '0.3em 0',
          },
        }}
      />
      <Sidebar setActiveTab={setActiveTab} />
      <Content>
        <Routes>
          <Route
            path="/"
            element={
              <DashboardHome
                dashboardData={dashboardData}
                empresaId={empresaId}
                donorsLocations={donorsLocations}
                nationalDonorsLocations={nationalDonorsLocations}
                filter={filter}
                setFilter={setFilter}
              />
            }
          />
          <Route path="/donorregistration" element={<DonorRegistration />} />
          <Route
            path="/recipientregistration"
            element={<RecipientRegistration />}
          />
          <Route path="/pairings" element={<Pairings />} />
          <Route path="/BancoDeDoadoras" element={<BancoDeDoadoras />} />
          <Route path="/Banco" element={<BancoNacionalDeDoadoras />} />
          <Route path="/ImageUploader" element={<ImageUploader />} />
        </Routes>
      </Content>
    </DashboardContainer>
  );
};

export default Dashboard;

