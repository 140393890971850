import React, { useState } from "react";
import {
  Modal,
  Box,
  Tabs,
  Tab,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import QuestionsModal from "./QuestionsModal";
import CreateUser from "./CreateUser"; // Supondo que você tenha um componente CreateUser

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
  maxHeight: "90vh", // Limitar a altura máxima
  overflowY: "auto", // Adicionar rolagem vertical
};

const SettingsModal = ({ open, onClose, empresaId }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleBack = () => {
    setSelectedTab(0);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {selectedTab !== 0 && (
            <IconButton
              onClick={handleBack}
              aria-label="back"
              sx={{ position: "absolute", left: 8, top: 8 }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            Configurações
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          centered
        >
          <Tab label="Geral" />
          <Tab label="Formulário" />
          <Tab label="Usuários" />
        </Tabs>
        <Divider sx={{ mb: 2 }} />
        {selectedTab === 0 && (
          <Box p={2}>
            <Typography variant="body1">Configurações Gerais</Typography>
            {/* Adicione mais configurações gerais aqui */}
          </Box>
        )}
        {selectedTab === 1 && (
          <QuestionsModal
            open={true}
            onClose={onClose}
            onBack={handleBack}
            empresaId={empresaId}
          />
        )}
        {selectedTab === 2 && (
          <Box p={2}>
            <CreateUser empresaId={empresaId} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default SettingsModal;
