import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Button,
  CircularProgress,
  Modal,
  Backdrop,
  Fade,
  Divider,
} from "@mui/material";
import {
  PhotoCamera,
  CheckCircle,
  Info as InfoIcon,
} from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import { jwtDecode } from "jwt-decode";
import Sidebar from "./Sidebar";
import DonorRegistration from "./DonorRegistration";
import RecipientRegistration from "./RecipientRegistration";
import Pairings from "./Pairings";
import BancoDeDoadoras from "./BancoDeDoadoras";
import BancoNacionalDeDoadoras from "./BancoNacionalDeDoadoras";
import theme from "../theme/theme";
import { Routes, Route } from "react-router-dom";
import Relatoriorapido from "./Relatoriorapido";

const ImageInput = ({ onChange, label }) => (
  <Card
    sx={{
      p: 3,
      mb: 2,
      backgroundColor: "#ffffff",
      boxShadow: 3,
      border: "1px solid #e0e0e0",
      borderRadius: "8px",
    }}
  >
    <Typography variant="h6" gutterBottom sx={{ color: "#1976d2" }}>
      {label}
    </Typography>
    <input
      accept="image/*"
      style={{ display: "none" }}
      id={`upload-${label}`}
      type="file"
      onChange={onChange}
    />
    <label htmlFor={`upload-${label}`}>
      <Button
        variant="contained"
        component="span"
        startIcon={<PhotoCamera />}
        sx={{
          borderRadius: "8px",
          padding: "10px 20px",
          backgroundColor: "#1976d2",
          "&:hover": {
            backgroundColor: "#115293",
            transition: "background-color 0.3s ease",
          },
        }}
      >
        Upload
      </Button>
    </label>
  </Card>
);

const ImageUploader = () => {
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [tokensData, setTokensData] = useState({ tokens: 0, freePairings: 0 });
  const [errorMessage, setErrorMessage] = useState("");

  const fetchTokensData = async () => {
    const jwtToken = localStorage.getItem("jwtToken");
    let empresaId = null;

    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      empresaId = decodedToken.empresaId;

      if (!empresaId) {
        setErrorMessage("Erro interno: Empresa ID não encontrado.");
        return;
      }

      try {
        const response = await axios.get(
          `https://genematch.app/api/get-tokens?empresaId=${empresaId}`,
          { headers: { Authorization: `Bearer ${jwtToken}` } }
        );

        if (response.data.valid) {
          setTokensData(response.data.tokensData);
        } else {
          setTokensData({ tokens: 0, freePairings: 0 });
          setErrorMessage(
            response.data.message || "Sem tokens válidos disponíveis."
          );
        }
      } catch (error) {
        setErrorMessage("Erro ao buscar tokens. Por favor, tente novamente.");
      }
    }
  };

  const validatePairing = async () => {
    const jwtToken = localStorage.getItem("jwtToken");
    let empresaId = null;

    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      empresaId = decodedToken.empresaId;

      if (!empresaId) {
        setErrorMessage("Erro interno: Empresa ID não encontrado.");
        return false;
      }

      try {
        const response = await axios.post(
          "https://genematch.app/api/validate-pairing",
          {},
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              empresaId: empresaId,
            },
          }
        );

        if (response.data.valid) {
          return true;
        } else {
          setErrorMessage(
            response.data.message || "Sem tokens válidos disponíveis."
          );
          return false;
        }
      } catch (error) {
        setErrorMessage("Erro ao validar tokens. Por favor, tente novamente.");
        return false;
      }
    }
  };

  const handleConfirmPairing = async () => {
    setLoadingMessage("Aguarde, estamos verificando os tokens válidos...");
    setIsLoading(true);
    setIsModalOpen(true);
    setConfirmationModalOpen(false);
    const isValid = await validatePairing();

    if (!isValid) {
      setIsLoading(false);
      setIsModalOpen(false);
      return;
    }

    analyzeFacialFeatures();
  };

  const analyzeFacialFeatures = async () => {
    if (image1 && image2) {
      const formData = new FormData();
      formData.append("image1", image1);
      formData.append("image2", image2);
      setLoadingMessage(
        "Aguarde, estamos analisando a semelhança entre a Receptora e Doadora..."
      );

      const messages = [
        "Calculando Simetria Facial...",
        "Calculando Similaridade do Nariz...",
        "Calculando Similaridade da Boca...",
        "Calculando Similaridade dos Olhos...",
        "Aguarde, estamos finalizando.",
      ];

      let messageIndex = 0;

      const intervalId = setInterval(() => {
        if (messageIndex < messages.length) {
          setLoadingMessage(messages[messageIndex]);
          messageIndex++;
        }
      }, 3000);

      const jwtToken = localStorage.getItem("jwtToken");
      let empresaId = null;

      if (jwtToken) {
        const decodedToken = jwtDecode(jwtToken);
        empresaId = decodedToken.empresaId;

        if (!empresaId) {
          setIsLoading(false);
          setIsModalOpen(false);
          setErrorMessage("Erro interno: Empresa ID não encontrado.");
          return;
        }
      }

      try {
        const response = await axios.post(
          "https://genematch.app/api/upload-imagesNOVO",
          formData,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              empresaId: empresaId,
            },
          }
        );

        const data = response.data;

        if (data && data.similarity !== undefined) {
          setAnalysisResults({
            similarity: data.similarity,
            mouth_similarity: data.mouth_similarity,
            nose_similarity: data.nose_similarity,
            eye_similarity: data.eye_similarity,
            receptora_face_type: data.receptora_face_type,
            doadora_face_type: data.doadora_face_type,
          });
        } else {
          setErrorMessage(
            "Resposta inesperada da API. Por favor, tente novamente."
          );
        }

        clearInterval(intervalId);
        setIsLoading(false);
        setIsModalOpen(false);
      } catch (error) {
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.error.includes("API keys for empresaId")
        ) {
          setErrorMessage(
            "Desculpe, este módulo não possui tokens suficientes para continuar. Por favor, entre em contato com o administrador em suporte@genematch.app."
          );
        } else {
          setErrorMessage(
            "Ocorreu um erro ao processar esta requisição. Por favor, tente novamente mais tarde."
          );
        }
        clearInterval(intervalId);
        setIsLoading(false);
        setIsModalOpen(false);
      }
    } else {
      alert("Por favor, selecione duas imagens para análise.");
    }
  };

  const handleAnalyzePhotosClick = async () => {
    await fetchTokensData();
    setConfirmationModalOpen(true);
  };

  const setActiveTab = (tab) => {};

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" sx={{ mt: 2, mb: 4, ml: 2, width: "100%" }}>
        <Box display="flex">
          <Sidebar setActiveTab={setActiveTab} />
          <Box
            component="main"
            flexGrow={1}
            p={3}
            sx={{ width: "100%", ml: -5 }}
          >
            <Divider sx={{ mb: 3 }} />
            <Box sx={{ mb: 3 }}>
              <Card
                sx={{
                  p: 3,
                  border: "1px solid #e0e0e0",
                  mb: 2,
                  backgroundColor: "#ffffff",
                  boxShadow: 2,
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h4" gutterBottom sx={{ color: "#1976d2" }}>
                  Carregue as Imagens para Análise
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ImageInput
                      label="Receptora"
                      onChange={(e) => setImage1(e.target.files[0])}
                    />
                    {image1 && (
                      <Card
                        sx={{
                          p: 2,
                          border: "1px solid #e0e0e0",
                          boxShadow: 3,
                          mt: 2,
                          backgroundColor: "#ffffff",
                          borderRadius: "8px",
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Preview Receptora
                          </Typography>
                          <CardMedia
                            component="img"
                            image={URL.createObjectURL(image1)}
                            alt="Preview Imagem 1"
                            sx={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "300px",
                              objectFit: "contain",
                              borderRadius: "8px",
                            }}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ImageInput
                      label="Doadora"
                      onChange={(e) => setImage2(e.target.files[0])}
                    />
                    {image2 && (
                      <Card
                        sx={{
                          p: 2,
                          border: "1px solid #e0e0e0",
                          boxShadow: 3,
                          mt: 2,
                          backgroundColor: "#ffffff",
                          borderRadius: "8px",
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            Preview Doadora
                          </Typography>
                          <CardMedia
                            component="img"
                            image={URL.createObjectURL(image2)}
                            alt="Preview Imagem 2"
                            sx={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "300px",
                              objectFit: "contain",
                              borderRadius: "8px",
                            }}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </Grid>
                </Grid>
                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAnalyzePhotosClick}
                    startIcon={<CheckCircle />}
                    sx={{
                      mt: 2,
                      borderRadius: "8px",
                      padding: "10px 20px",
                      backgroundColor: "#1976d2",
                      "&:hover": {
                        backgroundColor: "#115293",
                        transition: "background-color 0.3s ease",
                      },
                    }}
                  >
                    Analisar Fotos
                  </Button>
                </Box>
                {analysisResults && (
                  <Relatoriorapido results={analysisResults} />
                )}
                {errorMessage && (
                  <Typography variant="h6" color="error" sx={{ mt: 2 }}>
                    {errorMessage}
                  </Typography>
                )}
              </Card>
              <Card
                sx={{
                  p: 3,
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                  boxShadow: 2,
                  borderRadius: "8px",
                }}
              >
                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <InfoIcon sx={{ color: "#1976d2", mr: 1 }} />
                  <Typography variant="h5" sx={{ color: "#1976d2" }}>
                    Como realizar o pareamento rápido
                  </Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="body1" sx={{ mb: 1 }}>
                  1. Selecione a imagem da Receptora clicando no botão de upload
                  e escolha a imagem desejada.
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  2. Selecione a imagem da Doadora clicando no botão de upload e
                  escolha a imagem desejada.
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  3. Após selecionar ambas as imagens, clique no botão "Analisar
                  Fotos".
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  4. Aguarde enquanto as imagens são analisadas. Você verá uma
                  mensagem de progresso durante a análise.
                </Typography>
                <Typography variant="body1">
                  5. Após a conclusão da análise, os resultados serão exibidos
                  na tela.
                </Typography>
              </Card>
            </Box>
          </Box>
        </Box>
      </Container>

      <Modal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={confirmationModalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
              textAlign: "center",
              color: "black", // Garantir que o texto seja preto
            }}
          >
            <Typography variant="h6" sx={{ mb: 2, color: "black" }}>
              Você tem {tokensData.tokens} tokens e {tokensData.freePairings}{" "}
              pareamentos gratuitos disponíveis.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmPairing}
              sx={{ mt: 2 }}
            >
              Confirmar Analise
            </Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
              textAlign: "center",
            }}
          >
            {isLoading && (
              <>
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2, color: "black" }}>
                  {loadingMessage}
                </Typography>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default ImageUploader;
