// FormOptions.js
import React from "react";

const FormOptions = {
  hairColors: [
    { label: "Grisalhos", value: "GRISALHOS" },
    { label: "Loiros", value: "LOIROS" },
    { label: "Ruivos", value: "RUIVOS" },
    { label: "Castanhos", value: "CASTANHOS" },
    { label: "Pretos", value: "PRETOS" },
    { label: "Branco", value: "BRANCO" },
  ],
  skinColors: [
    { label: "Tipo I", value: "TIPO 1" },
    { label: "Tipo II", value: "TIPO 2" },
    { label: "Tipo III", value: "TIPO 3" },
    { label: "Tipo IV", value: "TIPO 4" },
    { label: "Tipo V", value: "TIPO 5" },
    { label: "Tipo VI", value: "TIPO 6" },
  ],
  eyeColors: [
    { label: "Íris azul-clara", value: "ÍRIS AZUL-CLARA" },
    { label: "ÍRIS azul-esverdeada", value: "ÍRIS AZUL-ESVERDEADA" },
    { label: "ÍRIS cinza", value: "ÍRIS CINZA" },
    { label: "ÍRIS azul-escura", value: "ÍRIS AZUL-ESCURA" },
    {
      label: "ÍRIS azul-cinza com manchas amarelas/marrons",
      value: "ÍRIS AZUL-CINZA COM MANCHAS AMARELAS/MARRONS",
    },
    {
      label: "ÍRIS cinza-verde com manchas amarelas/marrons",
      value: "ÍRIS CINZA-VERDE COM MANCHAS AMARELAS/MARRONS",
    },
    { label: "ÍRIS verde", value: "ÍRIS VERDE" },
    {
      label: "ÍRIS verde com manchas amarelas/marrons",
      value: "ÍRIS VERDE COM MANCHAS AMARELAS/MARRONS",
    },
    {
      label: "ÍRIS avelã (castanho-esverdeada)",
      value: "ÍRIS AVELÃ (CASTANHO-ESVERDEADA)",
    },
    { label: "ÍRIS âmbar", value: "ÍRIS ÂMBAR" },
    { label: "ÍRIS castanho-clara", value: "ÍRIS CASTANHO-CLARA" },
    { label: "ÍRIS castanho-escura", value: "ÍRIS CASTANHO-ESCURA" },
  ],
  bloodTypes: [
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
  ],
  hairTypes: [
    { label: "1A Lisos (Fino)", value: "1A LISOS (FINO)" },
    { label: "1B Lisos (Médio)", value: "1B LISOS (MÉDIO)" },
    { label: "1C Lisos (Grosso)", value: "1C LISOS (GROSSO)" },
    { label: "2A Ondulados (Fino)", value: "2A ONDULADOS (FINO)" },
    { label: "2B Ondulados (Médio)", value: "2B ONDULADOS (MÉDIO)" },
    { label: "2C Ondulados (Grosso)", value: "2C ONDULADOS (GROSSO)" },
    { label: "3A Cacheados (Solto)", value: "3A CACHEADOS (SOLTO)" },
    { label: "3B Cacheados (Apertado)", value: "3B CACHEADOS (APERTADO)" },
    { label: "4A Crespos (Soft)", value: "4A CRESPOS (SOFT)" },
    { label: "4B Crespos (Wiry)", value: "4B CRESPOS (WIRY)" },
    { label: "4C Crespos (Wiry)", value: "4C CRESPOS (WIRY)" },
  ],
  races: [
    { label: "Branco", value: "BRANCO" },
    { label: "Negro", value: "NEGRO" },
    { label: "Pardo", value: "PARDO" },
    { label: "Amarelo", value: "AMARELO" },
    { label: "Indígena", value: "INDÍGENA" },
  ],
};

export default FormOptions;
