import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Dashboard from "./Dashboard";
import DonorRegistration from './components/DonorRegistration';
import RecipientRegistration from "./components/RecipientRegistration";
import BancoNacionalDeDoadoras from "./components/BancoNacionalDeDoadoras";
import Sidebar from "./components/Sidebar";
import Pairings from "./components/Pairings";
import BancoDeDoadoras from "./components/BancoDeDoadoras";
import ImageUploader from "./components/ImageUploader";
import PareamentoView from "./components/PareamentoView";
import slogan from "./assets/slogan.png";
import brasil from "./assets/brasil.png";
import uniao from "./assets/uniao.png";
import portugal from "./assets/portugal.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TitleProvider } from "./titleContext/TitleContext";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import LoginPage from "./components/LoginPage"; 

const theme = createTheme({
  palette: {
    primary: {
      main: "#840fb5",
    },
  },
});

const AppContent = ({ isAuthenticated }) => {
  const location = useLocation();
  const shouldShowSidebar =
    isAuthenticated && location.pathname !== "/pareamento-view";

  return (
    <div style={{ display: "flex" }}>
      {shouldShowSidebar && <Sidebar />}
      <main style={{ flexGrow: 1, padding: "16px" }}>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/DonorRegistration" element={<DonorRegistration />} />
          <Route
            path="/RecipientRegistration"
            element={<RecipientRegistration />}
          />
          <Route
            path="/BancoNacionalDeDoadoras"
            element={<BancoNacionalDeDoadoras />}
          />
          <Route path="/Pairings" element={<Pairings />} />
          <Route path="/BancoDeDoadoras" element={<BancoDeDoadoras />} />
          <Route path="/ImageUploader" element={<ImageUploader />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/Pareamento-view" element={<PareamentoView />} />
          {/* Admin Console Routes */}
          
        </Routes>
      </main>
    </div>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const refreshAuthToken = async (refreshToken) => {
    try {
      const response = await axios.post('/api/refresh-token', { refreshToken });
      if (response.data.success) {
        localStorage.setItem('jwtToken', response.data.token);
        return response.data.token;
      }
    } catch (error) {
      console.error('Erro ao atualizar o token:', error);
    }
    return null;
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        let token = localStorage.getItem('jwtToken');
        if (token) {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 < Date.now()) {
            const refreshToken = localStorage.getItem('refreshToken');
            token = await refreshAuthToken(refreshToken);
            if (!token) {
              setIsAuthenticated(false);
              navigate("/");
              return;
            }
          }
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          navigate("/");
        }
      } else {
        setIsAuthenticated(false);
        navigate("/");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <TitleProvider>
        <AppContent isAuthenticated={isAuthenticated} />
      </TitleProvider>
    </ThemeProvider>
  );
};

const Root = () => (
  <Router>
    <App />
  </Router>
);

export default Root;
